export const sellerBiddingActions = {
    SELLER_BIDDING_RESET: 'SELLER_BIDDING_RESET',

    STORE_MIN_SETTLE_DATE: 'STORE_MIN_SETTLE_DATE',
    SELLER_BIDDING_STORE_SECURITIES: 'SELLER_BIDDING_STORE_SECURITIES',
    SELLER_BIDDING_STORE_HOLIDAYS: 'SELLER_BIDDING_STORE_HOLIDAYS',

    SELLER_BIDDING_QUICK_FEEDBACK_SENT: 'SELLER_BIDDING_QUICK_FEEDBACK_SENT',
    SELLER_BIDDING_STORE_TRADING_POSITIONS: 'SELLER_BIDDING_STORE_TRADING_POSITIONS',
    SELLER_BIDING_STORE_TRADING_POSITIONS_VALIDATION_RESULT: 'SELLER_BIDING_STORE_TRADING_POSITIONS_VALIDATION_RESULT',
    SELLER_BIDDING_TRADING_POSITION_SELECTION_CHANGE: 'SELLER_BIDDING_TRADING_POSITION_SELECTION_CHANGE',
    SELLER_BIDDING_TRADING_TRADE_ALL_SELECTION_CHANGE: 'SELLER_BIDDING_TRADING_TRADE_ALL_SELECTION_CHANGE',
    SELLER_BIDDING_TRADING_CONFIRMATION_VISIBLE: 'SELLER_BIDDING_TRADING_CONFIRMATION_VISIBLE',
    SELLER_BIDDING_TRADING_COMPANY_CHANGE: 'SELLER_BIDDING_TRADING_COMPANY_CHANGE',
    SELLER_BIDDING_TRADING_BID_CHANGE: 'SELLER_BIDDING_TRADING_BID_CHANGE',
    SELLER_BIDDING_TRADING_SETTLE_DATE_CHANGE: 'SELLER_BIDDING_TRADING_SETTLE_DATE_CHANGE',
    SELLER_BIDDING_TADING_COMMENT_CHANGE: 'SELLER_BIDDING_TADING_COMMENT_CHANGE',
    SELLER_BIDDING_SET_TARGET_LEVEL: 'SELLER_BIDDING_SET_TARGET_LEVEL',
    SELLER_BIDDING_TARGET_LEVEL_REQUEST: 'SELLER_BIDDING_TARGET_LEVEL_REQUEST',
    SELLER_BIDDING_TARGET_LEVEL_SUCCESS: 'SELLER_BIDDING_TARGET_LEVEL_SUCCESS',
    SELLER_BIDDING_TARGET_LEVEL_FAILURE: 'SELLER_BIDDING_TARGET_LEVEL_FAILURE',
    SELLER_BIDDING_SHOW_BID_LIST: 'SELLER_BIDDING_SHOW_BID_LIST',
    MARK_BIDS_SHOWN: 'SELLER_BIDDING_MARK_BIDS_SHOWN'
}
