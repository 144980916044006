import { BankContactsColumn } from '../../components/banks/types/BankContactsColumn';

export const contactsColumnsLabels: Partial<Record<BankContactsColumn, string>> = {
    [BankContactsColumn.fullName]: 'Full Name',
    [BankContactsColumn.linkedIn]: '',
    [BankContactsColumn.distributionListIcon]: '',
    [BankContactsColumn.title]: 'Title',
    [BankContactsColumn.email]: 'Email',
    [BankContactsColumn.officePhoneNumber]: 'Office',
    [BankContactsColumn.mobilePhoneNumber]: 'Mobile',
};
