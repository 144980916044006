export const feedbackTypes = {
    best: 1,
    tiedForBest: 2,

    cover: 3,
    coverSameHandle: 4,
    coverDifferentHandle: 5,
    coverExact: 6,
    coverHandle: 7,

    third: 15,
    thirdSameHandle: 16,
    thirdDifferentHandle: 17,
    thirdExact: 18,
    thirdHandle: 19,

    top3: 8,
    top3SameHandle: 9,
    top3DifferentHandle: 10,
    top3Exact: 11,
    top3Handle: 12,

    outOfTop3: 13,
    submitFinal: 14,
    fourth: 20,
    fifth: 21,
    stage1AboutToEndTimeout: 22
};
