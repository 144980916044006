export const chartIssuanceSpread = {
    chartLayout: {
        margin: { t: 7, r: 7, b: 40, l: 45 },
        font: { size: 12 },
    },
    tickColor: '#e4e4e4',
    tickFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f',
    },
    bar: '#D6EBFF',
    border: '#235692',
    zeroLineColor: '#e4e4e4',
    hoverlabel: {
        bgcolor: '#4a4a4a',
        bordercolor: '#4a4a4a',
        font: {
            color: '#ffffff',
            family: 'Roboto, Arial, Helvetica, sans-serif',
            size: 12
        },
        align: 'left',
        namelength: 2000
    },
    minAdditionalYAmount: 4,
};
