export const amrPipelineDetailedActions = {
    TRANSACTIONS_REQUESTING_STATE: 'PIPELINE_DETAILED_TRANSACTIONS_REQUESTING_STATE',
    TRANSACTION_DETAILS_REQUESTING_STATE: 'PIPELINE_DETAILED_TRANSACTION_DETAILS_REQUESTING_STATE',
    STORE_TRANSACTIONS: 'PIPELINE_DETAILED_STORE_TRANSACTIONS',
    STORE_TRANSACTION_DETAILS: 'PIPELINE_DETAILED_TRANSACTIONS_STORE_TRANSACTION_DETAILS',
    STORE_TRANSACTIONS_SEARCH_RESULTS: 'PIPELINE_DETAILED_STORE_TRANSACTIONS_SEARCH_RESULTS',
    TRANSACTION_DETAILS_IOIS_SUBMITTING: 'TRANSACTION_DETAILS_IOIS_SUBMITTING',
    STORE_DOCUMENTS_SEARCH_RESULTS: 'PIPELINE_DETAILED_STORE_DOCUMENTS_SEARCH_RESULTS',
    STORE_INITIAL_TRANSACTION: 'PIPELINE_DETAILED_STORE_INITIAL_TRANSACTION',
    STORE_ANALYTICS: 'STORE_ANALYTICS',
    SEARCH_TERM_CHANGE: 'PIPELINE_DETAILED_SEARCH_TERM_CHANGE',
    RESET_TRANSACTIONS: 'PIPELINE_DETAILED_RESET_TRANSACTIONS',
    STORE_IOIS: 'PIPELINE_DETAILED_STORE_IOIS',
    EDIT_IOIS: 'PIPELINE_DETAILED_EDIT_IOIS',
    SET_UNREAD_IOIS_READ: 'PIPELINE_DETAILED_SET_UNREAD_IOIS_READ',
    SHOW_INVESTOR_POPUP: 'PIPELINE_DETAILED_SHOW_INVESTOR_POPUP',
    RESET: 'PIPELINE_DETAILED_RESET'
};
