export const editPortfolioActions = {
    PORTFOLIO_LOADED: 'EDIT_PORTFOLIO_LOADED',
    PORTFOLIO_SAVED: 'EDIT_PORTFOLIO_SAVED',
    EDIT_PORTFOLIO_LOADING: 'EDIT_PORTFOLIO_LOADING',
    RESET: 'EDIT_PORTFOLIO_RESET',
    RENAME_DIALOG_VISIBLE: 'EDIT_PORTFOLIO_RENAME_DIALOG_VISIBLE',
    PORTFOLIO_DELETED: 'EDIT_PORTFOLIO_PORTFOLIO_DELETED',
    DELETE_PORTFOLIO_DIALOG: 'EDIT_PORTFOLIO_DELETE_PORTFOLIO_DIALOG',
    PORTFOLIO_UPDATE_REQUEST: 'PORTFOLIO_UPDATE_REQUEST',
    PORTFOLIO_UPDATE_SUCCESS: 'PORTFOLIO_UPDATE_SUCCESS',
    PORTFOLIO_UPDATE_FAILURE: 'PORTFOLIO_UPDATE_FAILURE',
    PORTFOLIO_SET_REQUESTING_EXPORT_STATE: 'PORTFOLIO_SET_REQUESTING_EXPORT_STATE',
}
