export const memberColumns = {
    status: 'status',
    fullName: 'fullName',
    phone: 'phone',
    role: 'role',
    email: 'email',
    dateRegistered: 'dateRegistered',
    dateLastLogin:'dateLastLogin',
    createdBy: 'createdBy',
    company: 'companyName',
    companyStatus: 'companyStatus'
}
