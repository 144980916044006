import { CollateralType } from "../../types/amr-pipeline/enums/CollateralType";
import { TransactionTypes } from "../../types/amr-pipeline/enums/TransactionType";
import { Currencies } from "../../types/enums/Currency";
import { IssuanceMonitorRatings } from "../../types/enums/Rating";
import { FilterType } from "../../types/filters/FilterGroup";
import { collateralTypes } from "../collateral-types";
import { dateRangeFilterOptions, dateTimeRangeFilterOptions } from "../date-range.filter";
import { coupon } from "./coupon";
import { equity, equityTitles } from "./equity";

export const pipelineFilters = {
    maxRangeValue: 999.99,
    searchItemsPerLoad: 20,
    visibleFilters: ['statuses', 'transactionTypes', 'collateralType', 'currency', 'managers', 'arrangers', 'ratings'],
    additionalFilters: [
        'rollerDeadline',
        'classNames',
        'pricingDate',
        'closingDate',
        'reinvestmentEnd',
        'nonCallEnd',
        'warf',
        'ds',
        'was',
        'parSubordination',
        'mvoc',
        'amrDeal',
        'euCompliance',
        'esg',
        'sofr',
        'isDebut',
        // enhancedCLO,
        'staticDeal',
        'trustees',
        'vintage',
        'coupons',
        'outOfNC',
        'outOfRI',
    ],
    defaultFilter: {
        euCompliance: {
            key: 'euCompliance',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'EU Compliance',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
        esg: {
            key: 'esg',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'ESG',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
        sofr: {
            key: 'sofr',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'SOFR',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
        isDebut: {
            key: 'isDebut',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'Debut CLO',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
        // Temporary Enhanced CLO hiding
        // enhancedCLO: {
        //     key: "enhancedCLO",
        //     type: FilterType.BooleanRadio,
        //     visible: false,
        //     text: "Enhanced CLO",
        //     disabled: false,
        //     selected: false,
        //     isApplied: true,
        //     filter: {
        //         selectedOption: null,
        //     },
        // },
        staticDeal: {
            key: 'staticDeal',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'Static Deal',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
        ratings: {
            key: 'ratings',
            type: FilterType.Select,
            visible: true,
            text: 'Rtg',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: IssuanceMonitorRatings.map(r => ({
                value: r.value,
                text: r.text || r.value,
                selected: false,
                visible: true,
                disabled: false,
            })),
        },
        transactionTypes: {
            key: 'transactionTypes',
            type: FilterType.Select,
            visible: true,
            text: 'Type',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: TransactionTypes.map(t => ({
                ...t,
                selected: false,
                disabled: false,
                visible: true,
            })),
        },
        statuses: {
            key: 'statuses',
            type: FilterType.Select,
            visible: true,
            text: 'Status',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [],
        },
        managers: {
            withSearch: true,
            type: FilterType.Select,
            visible: true,
            text: 'Manager',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [],
            key: 'managers',
        },
        arrangers: {
            withSearch: true,
            type: FilterType.Select,
            visible: true,
            text: 'Arranger',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [],
            key: 'arrangers',
        },
        trustees: {
            withSearch: true,
            type: FilterType.Select,
            visible: false,
            text: 'Trustee',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [],
            key: 'trustees',
        },
        pricingDate: {
            key: 'pricingDate',
            type: FilterType.Date,
            visible: false,
            text: 'Pricing Date',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.imDateFilters(),
        },
        closingDate: {
            key: 'closingDate',
            type: FilterType.Date,
            visible: false,
            text: 'Closing Date',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.imDateFilters(),
        },
        nonCallEnd: {
            key: 'nonCallEnd',
            type: FilterType.DateWithYearsRange,
            visible: false,
            text: 'NC End',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customYearsRange: { from: '', to: '' },
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.nonCallEnd(),
        },
        reinvestmentEnd: {
            key: 'reinvestmentEnd',
            type: FilterType.DateWithYearsRange,
            visible: false,
            text: 'RI End',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customYearsRange: { from: '', to: '' },
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.reinvestmentEnd(),
        },
        rollerDeadline: {
            key: 'rollerDeadline',
            type: FilterType.DateWithYearsAndTimeRange,
            visible: false,
            text: 'Roller Deadline',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
            },
            acceptableOptions: dateTimeRangeFilterOptions.rollerDeadline(),
        },
        was: {
            key: 'was',
            type: FilterType.Range,
            visible: false,
            text: 'WAS (%)',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: { from: '', to: '', maxValue: '100.00' },
        },
        warf: {
            key: 'warf',
            type: FilterType.Range,
            visible: false,
            text: 'WARF',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: { from: '', to: '', maxValue: '999999.99' },
        },
        ds: {
            key: 'ds',
            type: FilterType.Range,
            visible: false,
            text: 'DS',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: { from: '', to: '', maxValue: '999999.99' },
        },
        collateralType: {
            key: 'collateralType',
            type: FilterType.Select,
            visible: true,
            text: 'Collat',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [
                {
                    value: CollateralType.broadlySyndicated,
                    text: collateralTypes.BroadlySyndicated,
                    selected: false,
                    disabled: false,
                    visible: true,
                },
                {
                    value: CollateralType.middleMarket,
                    text: collateralTypes.MiddleMarket,
                    selected: false,
                    disabled: false,
                    visible: true,
                },
            ],
        },
        amrDeal: {
            key: 'amrDeal',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'Deal with AMR',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
        currency: {
            key: 'currency',
            type: FilterType.Select,
            visible: true,
            text: 'Ccy',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: Currencies.map(v => ({
                value: v,
                text: v,
                selected: false,
                disabled: false,
                visible: true,
            })),
        },
        classNames: {
            withSearch: true,
            type: FilterType.Select,
            visible: false,
            text: 'Class Name',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [],
            key: 'classNames',
        },
        parSubordination: {
            key: 'parSubordination',
            type: FilterType.Range,
            visible: false,
            text: 'C/E (%)',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: { from: '', to: '', maxValue: '100.00' },
        },
        mvoc: {
            key: 'mvoc',
            type: FilterType.Range,
            visible: false,
            text: 'MVOC (%)',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: { from: '', to: '', maxValue: '250.00' },
        },
        vintage: {
            key: 'vintage',
            type: FilterType.YearsDate,
            visible: false,
            text: 'Vintage',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: undefined,
                options: {
                    customDateRange: { from: null, to: null },
                },
            },
            acceptableOptions: dateRangeFilterOptions.yearsOptions(),
        },
        coupons: {
            key: 'coupons',
            type: FilterType.Radio,
            visible: false,
            text: 'Coupon',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
                options: coupon.map(c => ({
                    value: c,
                    text: c,
                    selected: false,
                    disabled: false,
                    visible: true,
                })),
            },
        },
        outOfNC: {
            key: 'outOfNC',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'Out of NC',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
        outOfRI: {
            key: 'outOfRI',
            type: FilterType.BooleanRadio,
            visible: false,
            text: 'Out of RI',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
    },
};

export const amrArrangerPipelineFilters = {
    maxRangeValue: 999.99,
    visibleFilters: ['collateralType', 'managers', 'arrangers', 'ncEnd', 'riEnd', 'euCompliance', 'equity', 'anchor'],
    defaultFilter: {
        euCompliance: {
            key: 'euCompliance',
            type: FilterType.BooleanRadio,
            visible: true,
            text: 'EU Compliance',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
        managers: {
            withSearch: true,
            type: FilterType.Select,
            visible: true,
            text: 'Manager',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [],
            key: 'managers',
        },
        arrangers: {
            withSearch: true,
            type: FilterType.Select,
            visible: true,
            text: 'Arranger',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [],
            key: 'arrangers',
        },
        collateralType: {
            key: 'collateralType',
            type: FilterType.Select,
            visible: true,
            text: 'Collat',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: [
                {
                    value: CollateralType.broadlySyndicated,
                    text: collateralTypes.BroadlySyndicated,
                    selected: false,
                    disabled: false,
                    visible: true,
                },
                {
                    value: CollateralType.middleMarket,
                    text: collateralTypes.MiddleMarket,
                    selected: false,
                    disabled: false,
                    visible: true,
                },
            ],
        },
        ncEnd: {
            key: 'ncEnd',
            type: FilterType.Range,
            visible: true,
            text: 'NC End (yrs)',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: { from: '', to: '', maxValue: '10.00' },
        },
        riEnd: {
            key: 'riEnd',
            type: FilterType.Range,
            visible: true,
            text: 'RI End (yrs)',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: { from: '', to: '', maxValue: '10.00' },
        },
        equity: {
            key: 'equity',
            type: FilterType.Radio,
            visible: true,
            text: 'Equity',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
                options: equity.map(e => ({
                    value: e,
                    text: equityTitles[e],
                    selected: false,
                    disabled: false,
                    visible: true,
                })),
            },
        },
        anchor: {
            key: 'anchor',
            type: FilterType.BooleanRadio,
            visible: true,
            text: 'Anchor AAA',
            disabled: false,
            selected: false,
            isApplied: true,
            filter: {
                selectedOption: null,
            },
        },
    },
};

export const analyticDefaultFilters = {
    users: [],
    tabs: [],
    date: {},
    dateOption: undefined,
};
