export const companyColumns = {
    status: 'status',
    legalName: 'name',
    members: 'members',
    salesRepresentatives: 'salesRepresentatives',
    role: 'role',
    primaryContact: 'primaryContact',
    dateRegistered: 'dateRegistered',
    createdBy: 'createdBy',
    isClearingBank: 'isClearingBank',
    subscription: 'subscription',
    subscriptionEnd: 'subscriptionEnd'
}

export const companyColumnsExportValues = {
    [companyColumns.status]: 1,
    [companyColumns.role]: 2,
    [companyColumns.legalName]: 3,
    [companyColumns.primaryContact]: 4,
    [companyColumns.members]: 5,
    [companyColumns.dateRegistered]: 6,
    [companyColumns.createdBy]: 7,
    [companyColumns.subscription]: 8,
    [companyColumns.subscriptionEnd]: 9
}
