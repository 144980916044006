export const searchSecuritiesActions = {
    SEARCH_TERM_CHANGE: 'SEARCH_SECURITIES_SEARCH_TERM_CHANGE',
    SEARCH_LOOKUP_CACHE: 'SEARCH_SECURITIES_SEARCH_LOOKUP_CACHE',
    SEARCH_LOOKUP: 'SEARCH_SECURITIES_SEARCH_LOOKUP',
    SECURITY_SEARCH_ITEM_ADD: 'SECURITY_SEARCH_ITEM_ADD',
    SECURITY_SEARCH_ITEM_REMOVE: 'SECURITY_SEARCH_ITEM_REMOVE',
    SECURITY_SEARCH_MOVE_BACK: 'SECURITY_SEARCH_MOVE_BACK',
    SECURITY_SEARCH_MOVE_FORWARD: 'SECURITY_SEARCH_MOVE_FORWARD',
    SECURITY_SEARCH_REMOVE_CURRENT_ITEM: 'SECURITY_SEARCH_REMOVE_CURRENT_ITEM',
    RESET: 'SEARCH_SECURITIES_RESET'
};
