export const bwicDateFilterOptions = {
    todayAndUpcoming: { key: 0, title: 'Today and upcoming' },
    thisWeek: { key: 1, title: 'This week' },
    lastWeek: { key: 2, title: 'Last week' },
    lastMonth: { key: 3, title: 'Last month' },
    lastYear: { key: 4, title: 'Last year' },
    custom: { key: 5, title: 'Custom' },
    unspecified: {key: 6, title: 'All'},
    current: { key: 7, title: 'Current' },
    today: { key: 8, title: 'Today' },
    currentInventory: { key: 9, title: 'Current Inventory' },
    toDefaultArray:  function() {
        return [
            this.todayAndUpcoming,
            this.thisWeek,
            this.lastWeek,
            this.lastMonth,
            this.lastYear,
            this.unspecified,
            this.custom
        ];
    }
};
