export const changePxTalkActions = {
    RESET: 'CHANGE_PXTALK_RESET',
    ADD_PXTALK: 'CHANGE_PXTALK_ADD_PX_TALK',
    TALK_CHANGE: 'CHANGE_PXTALK_TALK_CHANGE',
    LEVEL_TYPE_CHANGE: 'CHANGE_PXTALK_LEVEL_TYPE_CHANGE',
    DELETE_PX_TALK: 'CHANGE_PXTALK_DELETE_PX_TALK',
    STORE_PXTALKS: 'CHANGE_PXTALK_STORE_PXTALKS',
    VALIDATION_RESULT: 'CHANGE_PXTALK_VALIDATION_RESULT',
    NORMALIZED_LEVEL_CHANGE: 'CHANGE_PXTALK_NORMALIZED_LEVEL_CHANGE',
    NORMALIZING: 'CHANGE_PX_TALK_NORMALIZING',
    TALK_ERROR: 'CHANGE_PXTALK_TALK_ERROR'
};
