export const chartsPriceLevel = {
    margins: { t: 7, r: 0, b: 30, l: 35 },
    tickColor: '#e4e4e4',
    tickFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f'
    },
    layoutXAxisLineColor: '#dadada',
    xAxiasLineWidth: 1.5,
    hoverlabel: {
        bgcolor: '#4a4a4a',
        bordercolor: '#4a4a4a',
        font: {
            color: '#ffffff',
            family: 'Roboto, Arial, Helvetica, sans-serif',
            size: 12
        },
        align: 'left',
        namelength: 1000,
    }
};
