import { Rating } from '../../types/enums/Rating';

export const chartPxTalkDeviation = {
    margin: { t: 10, r: 0, b: 30, l: 25 },
    markerSize: 8,
    igBarColor: '#574f7d',
    hyBarColor: '#b3c4d0',
    tickColor: '#e4e4e4',
    font: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f'
    },
    ratings: [
        Rating.AAA,
        Rating.AA,
        Rating.A,
        Rating.BBB,
        Rating.BB,
    ],
    hoverlabel: {
        bgcolor: '#4a4a4a',
        bordercolor: '#4a4a4a',
        font: {
            color: '#ffffff',
            family: 'Roboto, Arial, Helvetica, sans-serif',
            size: 12
        },
        align: 'left'
    }
};
