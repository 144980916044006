export const chartBoxHorizontal = {
    margin: { t: 0, r: 0, b: 25, l: 0 },
    fillBoxColor: '#cceaee',
    boxMarkerColor: '#0096aa',
    kTalkMarkerColor: '#F0592A',
    chartHeight: 100,
    tickColor: '#e4e4e4',
    tickFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f'
    },
    yAxisRange: [0, 205],
    boxgap: 0.8,
    hoverlabel: {
        bgcolor: '#4a4a4a',
        bordercolor: '#4a4a4a',
        font: {
            color: '#ffffff',
            family: 'Roboto, Arial, Helvetica, sans-serif',
            size: 12
        },
        align: 'left'
    }
};
