export const contactsSaveActions = {
    RESET: 'CONTACTS_SAVE_RESET',
    CONTACT_SAVE_LOADING: 'CONTACT_SAVE_LOADING',
    LOAD_COMPANIES: 'CONTACTS_SAVE_LOAD_COMPANIES',
    LOAD_COMPANY_DOMAINS: 'CONTACTS_SAVE_LOAD_COMPANY_DOMAINS',
    INITIAL_CONTACTS: 'CONTACTS_SAVE_INITIAL_CONTACTS',
    COMPANIES_LOOKUP: 'CONTACTS_SAVE_COMPANIES_LOOKUP',
    SWITCH_VIEW: 'CONTACTS_SAVE_SWITCH_VIEW',
    CONTACTS_SAVED: 'CONTACTS_SAVE_CONTACTS_SAVED',
    STORE_CONFLICTS: 'CONTACTS_SAVE_STORE_CONFLICTS',
    RESET_CONFLICTS: 'RESET_CONFLICTS',
    EDIT_MODE: 'CONTACTS_SAVE_EDIT_MODE',
    DELETE_CONTACT: 'CONTACTS_DELETE_CONTACT',
    DELETE_DUPLICATE_CONTACT: 'CONTACTS_DELETE_DUPLICATE_CONTACT',
    ENSURE_CONFLICTS_RESOLVED: 'CONTACTS_ENSURE_CONFLICTS_RESOLVED'
};
