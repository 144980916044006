export const bidPlacementActions = {
    BID_PLACEMENT_SET_BID_CAN_SUBMIT: 'BID_PLACEMENT_SET_BID_CAN_SUBMIT',
    BID_PLACEMENT_BID_CHANGE: 'bidPlacements/LEVEL_CHANGE',
    BID_PLACEMENT_AXED_CHANGE: 'BID_PLACEMENT_AXED_CHANGE',
    BID_PLACEMENT_FINAL_CHANGE: 'BID_PLACEMENT_FINAL_CHANGE',
    BID_PLACEMENT_PASS_CHANGE: 'BID_PLACEMENT_PASS_CHANGE',
    BID_PLACEMENT_SET_BID_ERRORS: 'BID_PLACEMENT_SET_BID_ERRORS',
    SET_BID_CAN_SUBMIT: 'bidPlacement/SET_BID_CAN_SUBMIT_FLAG',
    REVERT: 'bidPlacement/REVERT',
    REVERT_ALL: 'bidPlacement/REVERT_ALL',
    COMMISSION_CHANGE: 'bidPlacement/COMMISSION_CHANGE'
}
