import { constants } from './constants';
import { formatUtils } from '../utils/format.utils';
import { moneyUtils } from '../utils/money.utils';

export const errorMessages = {
    signInFailed: 'Incorrect email or password.',
    setupPasswordLinkExpires: 'Sorry, the link you used has expired. Please try again using the most recent reset password email in your inbox, or ',
    setupPasswordLinkInvalid: 'Sorry, the link you used is invalid. Please try again using the most recent reset password email in your inbox, or ',
    empty: 'The field must not be empty',
    timeRange: 'The time should be between bids due and good until',
    dateRange: (from, to) => `The date should be between ${from} and ${to}`,
    fromValueGraterTo: 'From value should be not greater than To value',
    invalidValue: 'The field contains invalid value',
    tooLong: 'The field contains too long value',
    tooSmall: 'The field contains too small value',
    shouldBeNumber: 'The value should be a number',
    setFirst: field => `Set valid ${field} first`,
    setMoreThanTotalDuration: (totalDuration) => `Total of first round and improvement round should not be greater than ${totalDuration}`,
    passwordRequired: 'Password is required',
    confirmPasswordRequired: 'Confirm password is required',
    invalidNewPassword: 'New password is invalid',
    passwordsNotMatch: 'The password and confirmation password do not match',
    bidsDueShoulbBeLessThanGoodUntil: 'Start time should be earlier than end time',
    bwicTimeInvalidRange: (from, to, tz) => `Valid time frame from ${from} to ${to} ${tz}`,
    dateShouldNotBeInPast: `The date should not be in the past`,
    dateShouldBeInPast: `The date should be in the past`,
    bwicDateShouldNotBeWeekend: `The date should not be a weekend`,
    bwicDateShouldNotBeHoliday: `The date should not be a holiday`,
    bwicTimeShouldNotBeInPast: `The time should not be in the past`,
    fileSizeLimitExceeded: fileSizeLimitMb => `The file is more than ${fileSizeLimitMb}Mb. Please choose another one.`,
    documentsUploadLimitExceeded: documentsLimit => `Please select up to ${documentsLimit} files`,
    documentsLimitExceeded: limit => `File upload limit exceeded. It is allowed to have up to ${limit} documents per section.`,
    unexpectedError: 'An unexpected error has occurred. Please try again.',
    clipboardParseError: 'Clipboard data could not be parsed',
    invalidFileType: 'The file could not be uploaded. Only files with the following formats are allowed: xlsx, csv.',
    bidShouldBeGreater: 'New bid must be higher than the previous one',
    bidShouldBeGreaterThan: value => `Min improvement should be ${value}`,
    bidShouldBeBetween: `Price should be in the range of ${formatUtils.formatBid(constants.bidRange.min)} to ${formatUtils.formatBid(constants.bidRange.max)}`,
    shouldBeBetween: (min, max) => `The value should be in the range of ${min} to ${max}`,
    invalidColor: 'Invalid values chosen/entered',
    notificationServiceConnectionEstablished: 'Connection established',
    notificationServiceConnectionLost: 'Please check your network connectivity and try again',
    notificationServiceConnectionLostTitle: 'Connection Error',
    companyInformationCanNotBeChanged: 'Company Information can\'t be changed',
    userInformationCanNotBeChanged: 'User Information can\'t be changed',
    primaryContactUserCanNotBeMovedToAnotherCompany: 'Primary Contact User can\'t be moved to another Company. Please update User information.',
    identifierNotFount: 'Unknown security',
    portfolioSaveSecuritiesTitle: 'Unable to save the portfolio',
    portfolioNameIsRequired: 'Portfolio Name is required',
    gridDataItemsLimitExceeded: limit => `The system will not accept lists of over ${limit} records`,
    gridDataItemsLimitExceededTitle: 'Too many records.',
    targetLevelRange: `Target level should be in the range of ${constants.targetLevelRange.min.toFixed(4)} to ${constants.targetLevelRange.max.toFixed(4)}`,
    portfolioSaveSecuritiesMessage: 'Portfolio contains invalid securities. Please correct errors.',
    error: 'Error',
    invalidEmail: 'Email address is invalid',
    duplicateDealerEmail: 'Email is already in your dealers list',
    uploadingUserImageMaxSize: (mb) =>  `Image must be no larger than ${mb} Mb`,
    uploadingUserImageAcceptedFormats: (formats) => `Accepted formats are ${formats}`,
    maxLength: (value) => `Maximum length ${value} characters`,
    yupNumberMax: (e) => `Value must be less than or equal to ${String(e.max).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    yupNumberMin: (e) => `Value must be greater than or equal to ${String(e.min).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
    invalidRange: (min, max) => `Valid range is ${min}-${max}`,
    invalidRangeSize: (min, max) => `Size should be in the range of ${moneyUtils.money(min)} to ${moneyUtils.money(max)}`,
    maxContactsInCompany: (max) => `Request cannot be sent to more than ${max} sales representatives`,
    maxSizeLessThenMin: 'Max Size should be higher than the Min Size',
    datePickerInvalidDate: 'Invalid date',
    datePickerInvalidFromDate: 'Invalid From date',
    datePickerInvalidToDate: 'Invalid To date',
    fromDateBiggerThenToDate: 'From Date should be less than To Date',
    fromDateLaterThenToDate: 'From date should not be later than To date',
    refreshPage: 'Refresh the page to get the latest information.',
    cantSavePxTalk409: 'Can’t save Price Talk(s)',
    chooseSalesRepresentative: 'At least one Sales Representative should be chosen',
    wrongCurrentPassword: 'Wrong current password',
    ratingIsNotSpecified: 'Rating is not specified, select a rating from the list.',
    fromRangeBiggerThenToRange: 'From value should not be greater than To value',
    documentMaxFileSizeMessageText: (maxSize) => `The file you are trying to upload is larger than ${maxSize}mb. Please choose another one.`,
    documentMaxFileSizeMessageTitle: 'The file is too large',
    documentInvalidFileTypeText: (fileFormat, allowedFormats) => `The file '.${fileFormat}' could not be uploaded. Only files with the following formats are allowed: ${allowedFormats}`,
    documentInvalidFileTypeTitle: 'Invalid file type',
    wrongFormat: (characters) => `Invalid format specified. Must be filled in the ${characters} characters format`,
    wrongFormatRange: (min, max) => `Invalid format specified. Must be filled in between the ${min} to ${max} characters format`,
    settlementAccountBankTaxId: 'Invalid format specified. Must be filled in the \'xx-xxxxxxx\' format',
    emptyMultiSelect: 'At least one option should be chosen',
    valueCannotBeLess: 'The value cannot be less than the current.',
    lessThenMinBidIncrement: (minBidIncrement) => `The minimum bid increment should be ${minBidIncrement} relative to the previous bid.`,
    deskIsNotSpecified: 'Desk is not specified, select a desk from the list.',
    invalidIsinLength: 'ISIN (Rule 144A) should be 12 characters long'
};
