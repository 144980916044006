export const dashboardChartHeatMapConstants = {
    minimizedChartSize: 35,
    minimizedChartDefaultColor: '#F9F9F9',
    minimizedChartDefaultBorderColor: '#E0E0E0',
    maxColumns: 5,
    margin: { t: 1, r: 0, b: 25, l: 40 },
    colorScale: createColorScales(),
    whiteColorScale: [[0.0, '#fff'], [1, '#fff']],
    hours: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '00'],
    yLabels: ['1AM', '2AM', '3AM', '4AM', '5AM', '6AM', '7AM', '8AM', '9AM', '10AM', '11AM', '12PM', '1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM', '8PM', '9PM', '10PM', '11PM', '12AM'],
    lineColor: '#e4e4e4',
    annotationFontColors: ['#13396E', '#13396E', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF'],
    linkFontColor: '#678CA1',
    tickColor: '#e4e4e4',
    textColor: '#7f7f7f',
    tickFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f'
    },
    firstTimeSlot: 720,
    workingTimeInterval: 9,
    minHourForRedirect: 8,
    lineWidth: .5,
    hoverlabel: {
        bgcolor: '#4a4a4a',
        bordercolor: '#4a4a4a',
        font: {
            color: '#ffffff',
            family: 'Roboto, Arial, Helvetica, sans-serif',
            size: 12
        },
        align: 'left'
    }
};

function createColorScales() {
    const colors = ['#D2EAF8', '#9CC4E3', '#487EB2', '#235692', '#13396E'];
    const result = [[0, '#FFFFFF'], [0.00001, colors[0]]];
    for (let i = 1; i < 10; i++) {
        for (let k = 0; k < 10; k++) {
            for (let l = 0; l < 10; l++) {
                result.push([(i / 10) + (k / 100) + (l / 1000), colors[Math.ceil(i / 2)] || colors[colors.length - 1]])
            }
        }
    }
    result.push([1, colors[colors.length - 1]]);
    return result
}
