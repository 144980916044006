export const amrTrancheStatuses = {
    draft: 'Draft',
    initiated: 'Initiated',
    scheduled: 'Scheduled',
    acceptingBids: 'AcceptingBids',
    pendingResultCalculation: 'PendingResultCalculation',
    resultCalculation: 'ResultCalculation',
    allocationCorrection: 'AllocationCorrection',
    settlement: 'Settlement',
    newMarginApplied: 'NewMarginApplied',
    canceled: 'Canceled',
    failed: 'Failed',

    getStatusText: function (status) {
        switch (status) {
            case this.acceptingBids: return 'Bidding';
            case this.pendingResultCalculation: return 'Pending Result';
            case this.resultCalculation: return'Result';
            case this.allocationCorrection: return 'Allocation Correction';
            case this.newMarginApplied: return 'Success';
            case this.failed: return 'Not Refinanced';
            default: return status.charAt(0).toUpperCase() + status.slice(1);
        }
    }
}
