import { Rating } from '../../types/enums/Rating';
import { RatingGroup } from '../../types/dashboard/RatingGroup';

export const ratingColors: {[rating: string]: string} = {
    [Rating.AAA]: '#235692',
    [Rating.AA]: '#669FC3',
    [Rating.A]: '#AFE0EB',
    [Rating.BBB]: '#E4E4E4',
    [Rating.BB]: '#BDBDBD',
    [Rating.B]: '#9B9B9B',
    [Rating.NR]: '#4A4A4A',
    [RatingGroup.OTHER]: '#262626'
};
