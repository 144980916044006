import { InvestmentTeamColumn } from '../../components/clo-managers/types/InvestmentTeamColumn';

export const investmentTeamColumnsLabels: Partial<Record<InvestmentTeamColumn, string>> = {
    [InvestmentTeamColumn.fullName]: 'Full Name',
    [InvestmentTeamColumn.linkedIn]: '',
    [InvestmentTeamColumn.title]: 'Title',
    [InvestmentTeamColumn.email]: 'Email',
    [InvestmentTeamColumn.phoneNumber]: 'Phone',
    [InvestmentTeamColumn.location]: 'Location',
    [InvestmentTeamColumn.yrsAtCompany]: 'Yrs at firm',
    [InvestmentTeamColumn.yrsExperience]: 'Yrs experience',
    [InvestmentTeamColumn.meetingContact]: 'Meeting Contact',
};
