export const chartBarSameDay = {
    chartLayout: {
        margin: { t: 7, r: 0, b: 35, l: 30 },
        font: { size: 12 },
        bargap: 0.15,
    },
    dTick: 10,
    yAxisRange: [0, 105],
    xAxisRange: [1.5, 7.5],
    tickColor: '#e4e4e4',
    tickFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f'
    },
    barColor: '#75afbd',
    zeroLineColor: '#e4e4e4',
    currencySign: {  USD: '$', EUR: '€' },
    annotationMargin: 3.5,
    serverDateType: 'MM/DD/YYYY',
    hoverlabel: {
        bgcolor: '#4a4a4a',
        bordercolor: '#4a4a4a',
        font: {
            color: '#ffffff',
            family: 'Roboto, Arial, Helvetica, sans-serif',
            size: 12
        },
        align: 'left'
    }
};
