export const tradeStatuses = {
    pending: { key: 1, title: 'Pending' },
    affirmed: { key: 2, title: 'Affirmed' },
    rejected: { key: 3, title: 'Rejected' },
    canceled: { key: 4, title: 'Canceled' },
    toArray: function () {
        return [this.pending, this.affirmed, this.rejected, this.canceled];
    },
    getByKey: function (key) {
        return this.toArray().find(s => s.key === key);
    }
};
