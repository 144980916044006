export const clientsActivityChart = {
    chartLayout: {
        margin: { t: 7, r: 7, b: 40, l: 42 },
        font: { size: 12 },
        bargap: 0.1,
        bargroupgap: 0.4,
    },
    tickColor: '#e4e4e4',
    tickFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f',
    },
    barColors: ['#235692', '#669FC3', '#AFE0EB', '#CECECE', '#9B9B9B'],
    zeroLineColor: '#e4e4e4',
    hoverlabel: {
        bgcolor: '#4a4a4a',
        bordercolor: '#4a4a4a',
        font: {
            color: '#ffffff',
            family: 'Roboto, Arial, Helvetica, sans-serif',
            size: 12,
        },
        align: 'left',
    },
};
