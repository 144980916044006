export const changeColorActions = {
    SET_COLOR: 'CHANGE_COLOR_SET_COLOR',
    CHANGE_COLOR_SET_DM: 'CHANGE_COLOR_SET_DM',
    RESET: 'CHANGE_COLOR_RESET',
    TRADED_CHANGE: 'CHANGE_COLOR_TRADED_CHANGE',
    LEVEL_SPECIFICATION_CHANGE: 'CHANGE_COLOR_LEVEL_SPECIFICATION_CHANGE',
    LEVEL_CHANGE: 'CHANGE_COLOR_LEVEL_CHANGE',
    NORMALIZED_LEVEL_CHANGE: 'CHANGE_COLOR_NORMALIZED_LEVEL_CHANGE',
    SET_LEVEL_ERROR: 'CHANGE_COLOR_SET_LEVEL_ERROR',
    SET_COLOR_ERROR: 'CHANGE_COLOR_SET_COLOR_ERROR',
    LEVEL_TYPE_CHANGE: 'CHANGE_COLOR_LEVEL_TYPE_CHANGE',
    NORMALIZING: 'CHANGE_COLOR_NORMALIZING'
};
