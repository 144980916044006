export const verifyIdentityActions = {
    VERIFY_IDENTITY_SET_SELECTED_METHOD: 'VERIFY_IDENTITY_SET_SELECTED_METHOD',
    VERIFY_IDENTITY_REQUIRED: 'VERIFY_IDENTITY_REQUIRED',
    VERIFY_IDENTITY_CODE_REUESTING: 'VERIFY_IDENTITY_CODE_REUESTING',
    VERIFY_IDENTITY_CODE_CONFIRMING: 'VERIFY_IDENTITY_CODE_CONFIRMING',
    VERIFY_IDENTITY_CODE_SENT: 'VERIFY_IDENTITY_CODE_SENT',
    VERIFY_IDENTITY_CODE_INVALID: 'VERIFY_IDENTITY_CODE_INVALID',
    VERIFY_IDENTITY_RESET: 'VERIFY_IDENTITY_RESET'

}
