import { DateFilterOption } from "../types/filters/DateFilterOption";
import { DateTimeFilterOption } from '../types/filters/DateTimeFilterOption';
import { DateRangeOption, DateTimeRangeOption } from "../types/user-config/UserConfigFilter";

export const dateRangeFilterOptions = {
    [DateRangeOption.NextOneAndHalf]: {
        key: 0,
        title: '0Y - 1.5Y',
        from: 0,
        to: 1.5,
    } as DateFilterOption,
    [DateRangeOption.NextThreeAndHalf]: {
        key: 1,
        title: '0Y - 3.5Y',
        from: 0,
        to: 3.5,
    } as DateFilterOption,
    [DateRangeOption.FromOneAndHalfToThreeAndHalf]: {
        key: 2,
        title: '1.5Y - 3.5Y',
        from: 1.5,
        to: 3.5,
    } as DateFilterOption,
    [DateRangeOption.FromThreeAndHalfToFiveAndHalf]: {
        key: 3,
        title: '3.5Y - 5.5Y',
        from: 3.5,
        to: 5.5,
    } as DateFilterOption,
    [DateRangeOption.YearsRange]: { key: 4, title: 'Years Period' } as DateFilterOption,
    [DateRangeOption.ThisWeek]: {
        key: 5,
        title: 'This Week',
        formatTitle: (from: string, to: string) => `This Week (${from} - ${to})`,
    } as DateFilterOption,
    [DateRangeOption.LastWeek]: {
        key: 6,
        title: 'Last Week',
        formatTitle: (from: string, to: string) => `Last Week (${from} - ${to})`,
    } as DateFilterOption,
    [DateRangeOption.ThisMonth]: {
        key: 7,
        title: 'This Month',
        formatTitle: (from: string, to: string) => `This Month (${from} - ${to})`,
    } as DateFilterOption,
    [DateRangeOption.LastMonth]: {
        key: 8,
        title: 'Last Month',
        formatTitle: (from: string, to: string) => `Last Month (${from} - ${to})`,
    } as DateFilterOption,
    [DateRangeOption.LastThreeMonth]: {
        key: 9,
        title: 'Last 3 Months',
        formatTitle: (from: string, to: string) => `Last 3 Months (${from} - ${to})`,
    } as DateFilterOption,
    [DateRangeOption.LastSixMonth]: {
        key: 10,
        title: 'Last 6 Months',
        formatTitle: (from: string, to: string) => `Last 6 Months (${from} - ${to})`,
    } as DateFilterOption,
    [DateRangeOption.Custom]: { key: 11, title: 'Custom' } as DateFilterOption,
    [DateRangeOption.Today]: { key: 12, title: 'Today', formatTitle: () => 'Today' } as DateFilterOption,
    [DateRangeOption.ThisYear]: {
        key: 13,
        title: 'This Year',
        formatTitle: (year: string) => `This Year (${year})`,
    } as DateFilterOption,
    [DateRangeOption.LastYear]: {
        key: 14,
        title: 'Last Year',
        formatTitle: (from: string, to: string) => `Last Year (${from} - ${to})`,
    } as DateFilterOption,
    [DateRangeOption.CurrentInventory]: {
        key: 15,
        title: 'Current Inventory',
        pureOption: true,
        formatTitle: () => 'Current Inventory',
    } as DateFilterOption,
    [DateRangeOption.All]: { key: 16, title: 'All', pureOption: true } as DateFilterOption,
    [DateRangeOption.TodayAndUpcoming]: {
        key: 17,
        title: 'Today and Upcoming',
        formatTitle: () => `Today and Upcoming`,
    } as DateFilterOption,
    [DateRangeOption.ThisWeekMondayToFriday]: {
        key: 18,
        title: 'This Week',
        formatTitle: (from: string, to: string) => `This Week (${from} - ${to})`,
    } as DateFilterOption,
    [DateRangeOption.Yesterday]: { key: 19, title: 'Yesterday', formatTitle: () => 'Yesterday' } as DateFilterOption,
    toArray: function () {
        return [
            this.All,
            this.CurrentInventory,
            this.NextOneAndHalf,
            this.NextThreeAndHalf,
            this.FromOneAndHalfToThreeAndHalf,
            this.FromThreeAndHalfToFiveAndHalf,
            this.YearsRange,
            this.ThisWeek,
            this.LastWeek,
            this.ThisMonth,
            this.LastMonth,
            this.LastThreeMonth,
            this.LastSixMonth,
            this.LastYear,
            this.Custom,
            this.Today,
            this.TodayAndUpcoming,
            this.ThisYear,
            this.ThisWeekMondayToFriday,
            this.Yesterday,
        ];
    },
    dateOptions: function () {
        return [
            this.ThisWeek,
            this.LastWeek,
            this.ThisMonth,
            this.LastMonth,
            this.LastThreeMonth,
            this.LastSixMonth,
            this.LastYear,
            this.Custom,
            this.ThisWeekMondayToFriday,
            this.TodayAndUpcoming,
            this.Yesterday,
        ];
    },

    imDateFilters: function () {
        return [
            this.ThisWeek,
            this.LastWeek,
            this.ThisMonth,
            this.LastMonth,
            this.LastThreeMonth,
            this.LastSixMonth,
            this.LastYear,
            this.Custom,
        ];
    },

    yearsOptions: function () {
        return [this.ThisYear, this.Custom];
    },

    nonCallEnd: function () {
        return [this.NextOneAndHalf, this.FromOneAndHalfToThreeAndHalf, this.YearsRange, this.Custom];
    },
    maturity: function () {
        return [{ ...this.All, default: true, pureOption: false }, this.Custom];
    },
    closing: function () {
        return [this.LastMonth, this.LastThreeMonth, this.LastSixMonth, this.LastYear, this.Custom];
    },
    reinvestmentEnd: function () {
        return [this.NextThreeAndHalf, this.FromThreeAndHalfToFiveAndHalf, this.YearsRange, this.Custom];
    },
    vintage: function () {
        return [this.ThisYear, this.Custom];
    },
    inventoryOptions: function () {
        return [
            { ...this.All, default: true },
            this.CurrentInventory,
            this.ThisWeekMondayToFriday,
            this.LastWeek,
            this.LastMonth,
            this.LastYear,
            this.Custom,
        ];
    },
    bwicMonitor: function () {
        return [
            this.TodayAndUpcoming,
            this.Yesterday,
            this.ThisWeekMondayToFriday,
            this.LastWeek,
            this.LastMonth,
            this.LastYear,
            { ...this.All, default: true },
            this.Custom,
        ];
    },
    bwicMonitorSellBuy: function () {
        return [
            this.TodayAndUpcoming,
            this.ThisWeekMondayToFriday,
            this.LastWeek,
            this.LastMonth,
            this.LastYear,
            { ...this.All, default: true },
            this.Custom,
        ];
    },
};

export const dateTimeRangeFilterOptions = {
    [DateTimeRangeOption.TodayWithTime]: { key: 0, title: "Today", formatTitle: () => "Today" } as DateTimeFilterOption,
    [DateTimeRangeOption.DateWithTime]: { key: 1, title: "Date" } as DateTimeFilterOption,
    [DateTimeRangeOption.DateWithTimePeriod]: { key: 2, title: "Date Period" } as DateTimeFilterOption,
    rollerDeadline: function () {
        return [
            this.Today,
            this.DateWithTime,
            this.DateWithTimePeriod,
        ];
    },
};
