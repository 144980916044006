export const searchTransactionsActions = {
    SEARCH_TERM_CHANGE: 'SEARCH_TRANSACTIONS_SEARCH_TERM_CHANGE',
    SEARCH_LOOKUP_CACHE: 'SEARCH_TRANSACTIONS_SEARCH_LOOKUP_CACHE',
    SEARCH_RESET_LOOKUP_CACHE: 'SEARCH_TRANSACTIONS_RESET_LOOKUP_CACHE',
    SEARCH_LOOKUP: 'SEARCH_TRANSACTIONS_SEARCH_LOOKUP',
    SEARCH_ITEM_ADD: 'SEARCH_TRANSACTIONS_ITEM_ADD',
    SEARCH_ITEM_REMOVE: 'SEARCH_TRANSACTIONS_ITEM_REMOVE',
    SEARCH_MOVE_BACK: 'SEARCH_TRANSACTIONS_MOVE_BACK',
    SEARCH_MOVE_FORWARD: 'SEARCH_TRANSACTIONS_MOVE_FORWARD',
    SEARCH_REMOVE_CURRENT_ITEM: 'SEARCH_TRANSACTIONS_REMOVE_CURRENT_ITEM',
    SEARCH_RESET: 'SEARCH_TRANSACTIONS_RESET',
    SEARCH_ITEMS_REQUESTING: 'SEARCH_ITEMS_REQUESTING',
}
