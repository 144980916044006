export const dashboardChartBar = {
    label: {
        bgColor: '#4a4a4a',
        fontColor: '#fff',
        fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
        bordercolor: '#4a4a4a',
        size: 12
    },
    tickColor: '#e4e4e4',
    tickFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f'
    },
    zeroLineColor: '#e4e4e4',
    margin: { t: 3, r: 0, b: 35, l: 30 },
    yAxisDTick: 50000000,
    barWith: 0.131,
    fontSize: 12,
    xAxisRange: [-.5, 2.5],
    barGap: 0.15,
    barGroupGap: 0.1,
};
