export const processActions = {
    MIN_DATE: 'MIN_DATE',
    CHANGE_DATE: 'CHANGE_DATE',
    HOLIDAYS: 'HOLIDAYS',
    NEXT_BUSINESS_DAY: 'PROCESS_NEXT_BUSINESS_DAY',

    BIDS_DUE: 'BIDS_DUE',
    GOOD_UNTIL: 'GOOD_UNTIL',

    DAYS_TO_SETTLE: 'DAYS_TO_SETTLE',

    TIME_ZONE: 'TIME_ZONE',
    SET_DISABLED_TIME: 'PROCESS_SET_DISABLED_TIME',

    VALIDATE: 'VALIDATE_PROCESS',

    ADD_TEMPLATES: 'ADD_TEMPLATES',
    SELECT_TEMPLATE: 'SELECT_TEMPLATE',

    EARLY_TRADES_ENABLED: 'EARLY_TRADES_ENABLED',
    RESERVE_LEVELS_APPLY_ENABLED: 'RESERVE_LEVELS_APPLY_ENABLED',
    RULES_TEXT: 'PROCESS_RULES_TEXT',
    SET_STAGE1_DEADLINE: 'TPL_SET_STAGE1_DEADLINE',
    SET_IMPROVEMENT_ROUND: 'TPL_SET_IMPROVEMENT_ROUND',
    SET_MIN_BID_INCREMENT: 'SET_MIN_BID_INCREMENT',
    SET_LEVEL_SPECIFICATION_TYPE: 'TPL_SET_LEVEL_SPECIFICATION_TYPE',
    SET_OPEN_BIDDING_IMPROVER_COUNT: 'TPL_SET_OPEN_BIDDING_IMPROVER_COUNT',
    CUT_OFF_REMINDER_MINS:'CUT_OFF_REMINDER_MINS',
    RESET_CUT_OFF_REMINDER_MINS:'RESET_CUT_OFF_REMINDER_MINS',
    AUTO_FEEDBACK_DELAY_MINS:'AUTO_FEEDBACK_DELAY_MINS',
    AUTO_FEEDBACK_DELAY_ENABLED:'AUTO_FEEDBACK_DELAY_ENABLED',
    AUTO_FEEDBACK_DELAY_DISABLED:'AUTO_FEEDBACK_DELAY_DISABLED',
    MIN_BID_INCREMENT_CHANGED:'MIN_BID_INCREMENT_CHANGED',
    MIN_BID_INCREMENT_ENABLED:'MIN_BID_INCREMENT_ENABLED',
    MIN_BID_INCREMENT_DISABLED:'MIN_BID_INCREMENT_DISABLED',

    SET_TEMPLATE_DIRTY_STATE: 'SET_TEMPLATE_DIRTY_STATE',

    TITLE_CHANGE: 'PROCESS_TITLE_CHANGE',
    DESCRIPTION_CHANGE: 'PROCESS_DESCRIPTION_CHANGE',
    RESET: 'RESET_PROCESS',
    RESET_CHANGES: 'RESET_CHANGES',

    JUMP_BALL_STAGE_1_END_MANUAL: 'JUMP_BALL_STAGE_1_END_MANUAL',
    JUMP_BALL_STAGE_1_END_AUTO: 'JUMP_BALL_STAGE_1_END_AUTO',
    JUMP_BALL_STAGE_1_END_AUTO_MINS: 'JUMP_BALL_STAGE_1_END_AUTO_MINS',
    JUMP_BALL_STAGE_2_PARTICIPANTS_CHANGE: 'JUMP_BALL_STAGE_2_PARTICIPANTS_CHANGE',
    JUMP_BALL_STAGE_2_DURATION_MINS: 'JUMP_BALL_STAGE_2_DURATION_MINS',
    JUMP_BALL_STAGE_2_DURATION_UNSPECIFIED: 'JUMP_BALL_STAGE_2_DURATION_UNSPECIFIED',
    JUMP_BALL_STAGE_2_DURATION_PRESET: 'JUMP_BALL_STAGE_2_DURATION_PRESET',
    
    TOP_X_STAGE_1_END_MANUAL: 'TOP_X_STAGE_1_END_MANUAL',
    TOP_X_STAGE_1_END_AUTO: 'TOP_X_STAGE_1_END_AUTO',
    TOP_X_STAGE_1_END_AUTO_MINS: 'TOP_X_STAGE_1_END_AUTO_MINS',
    TOP_X_STAGE_2_PARTICIPANTS_CHANGE: 'TOP_X_STAGE_2_PARTICIPANTS_CHANGE',
    TOP_X_STAGE_2_DURATION_MINS: 'TOP_X_STAGE_2_DURATION_MINS',
    TOP_X_STAGE_2_DURATION_UNSPECIFIED: 'TOP_X_STAGE_2_DURATION_UNSPECIFIED',
    TOP_X_STAGE_2_DURATION_PRESET: 'TOP_X_STAGE_2_DURATION_PRESET',
   
    BEST_FOOT_FORWARD_DURATION_MINS: 'BEST_FOOT_FORWARD_DURATION_MINS',
    SET_ALL_TO_ALL_PROTOCOL: 'SET_ALL_TO_ALL_PROTOCOL'
};
