import { CollateralType } from '../types/amr-pipeline/enums/CollateralType';

interface Types {
    [key: string]: string,
}

export const collateralTypes: Types = {
    [CollateralType.broadlySyndicated]: 'Broadly Syndicated',
    [CollateralType.middleMarket]: 'Middle Market',
};

export const collateralTypesAbbr: Types = {
    [CollateralType.broadlySyndicated]: 'BSL',
    [CollateralType.middleMarket]: 'MM',
};

export const collateralTypesSelectOptions = [
    {
        value: CollateralType.broadlySyndicated,
        label: collateralTypes[CollateralType.broadlySyndicated],
    },
    {
        value: CollateralType.middleMarket,
        label: collateralTypes[CollateralType.middleMarket],
    }
]
