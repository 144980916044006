export const chartDtcIgHyConstants = {
    margin: { t: 10, r: 0, b: 30, l: 30 },
    annotationMargin: 5,
    igBarColor: '#5c4f84',
    hyBarColor: '#b3c4d0',
    tickColor: '#e4e4e4',
    font: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f'
    },
    annotationsFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#4a4a4a'
    },
    barWidth: 0.5,
    hoverlabel: {
        bgcolor: '#4a4a4a',
        bordercolor: '#4a4a4a',
        font: {
            color: '#ffffff',
            family: 'Roboto, Arial, Helvetica, sans-serif',
            size: 12
        },
        align: 'left'
    },
    yAxesMaxValueLineMargin: 6,
};
