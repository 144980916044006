import { Rating } from '../../types/enums/Rating';
import { RatingGroup } from '../../types/dashboard/RatingGroup';

export const priceLevelBarColors: { [rating: string]: { bar: string; border: string } } = {
    [Rating.AAA]: {
        bar: '#d6ebff',
        border: '#235692'
    },
    [Rating.AA]: {
        bar: '#d6ebff',
        border: '#669fc3'
    },
    [Rating.A]: {
        bar: '#ddf9ff',
        border: '#afe0eb'
    },
    [Rating.BBB]: {
        bar: '#f9f9f9',
        border: '#cecece'
    },
    [Rating.BB]: {
        bar: '#f5f5f5',
        border: '#bdbdbd'
    },
    [Rating.B]: {
        bar: '#e4e4e4',
        border: '#9b9b9b'
    },
    [Rating.NR]: {
        bar: '#e4e4e4',
        border: '#4a4a4a'
    },
    [RatingGroup.OTHER]: {
        bar: '#e4e4e4',
        border: '#262626'
    },
};
