export const dashboardChartBox = {
    margin: { t: 7, r: 0, b: 22, l: 35 },
    minBottomMargin: 25,
    xAxisMinDatesCount: 4,
    fillBoxColor: '#d3f0ff',
    boxMarkerColor: '#235692',
    scatterMarkerColor: '#262626',
    kTalkMarkerColor: '#F0592A',
    colorLineColor: '#37A84A',
    layoutXAxisLineColor: '#dadada',
    tickColor: '#e4e4e4',
    hoverLabelBorderColor: '#fff',
    tickFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f'
    },
    data0LineWidth: 1,
    pxTalkLimit: 200,
    minXRange: 5,
    markerSize: 9,
    hoverlabel: {
        bgcolor: '#4a4a4a',
        font: { color: '#ffffff' },
        align: 'left'
    },
    nTicks: 6,
};
