export const companyStatuses = {
    active: { statusCode: 1, title: 'Active', className: 'active' },
    blocked: { statusCode: 2, title: 'Blocked', className: 'blocked' },
    registrationPending: { statusCode: 3, title: 'Pending Registration', className: 'invited' },
    registrationSubmitted: { statusCode: 4, title: 'Pending Approval', className: 'invited' },
	registrationRejected: { statusCode: 5, title: 'Registration Rejected', className: 'blocked' },
	offPlatform: { statusCode: 6, title: 'Off-Platform', className: 'off-platform' },

    getByStatusCode: function (statusCode) {
		switch (+statusCode) {
			case this.offPlatform.statusCode:
				return this.offPlatform;

            case this.active.statusCode:
                return this.active;

            case this.blocked.statusCode:
                return this.blocked;

            case this.registrationPending.statusCode:
                return this.registrationPending;

            case this.registrationSubmitted.statusCode:
                return this.registrationSubmitted;

            default:
                return this.registrationRejected;
        }
    }
}
