export const aaaPriceLevelConstants = {
    dateFormat: 'MMM<br> \'YY',
    layoutFont: {
        size: 12
    },
    margin: { t: 8, r: 10, b: 37, l: 45 },
    hoverlabel: {
        bgcolor: '#4a4a4a',
        bordercolor: '#4a4a4a',
        font: {
            color: '#ffffff',
            family: 'Roboto, Arial, Helvetica, sans-serif',
            size: 12
        },
        align: 'left'
    },
    tickColor: '#e4e4e4',
    tickFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f'
    },
    titlefont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 16,
        color: '#7f7f7f'
    },
    avgColorMarker: {
        color: '#24b36b'
    },
    avgPriceTalkMarker: {
        color: '#f0592a'
    },
    shapeLine: {
        color: '#235692',
        width: 2.5,
        dash: 'dot'
    }
};
