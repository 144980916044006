export const tradesActions = {
    TRADES_STORE_TRADES: 'TRADES_STORE_TRADES',
    TRADES_RESET_TRADES: 'TRADES_RESET_TRADES',
    TRADES_RESET: 'TRADES_RESET',
    TRADES_SEARCH_IN_PROGRESS: 'TRADES_SEARCH_IN_PROGRESS',

    TRADES_FILTER_REPORTED: 'TRADES_FILTER_REPORTED',
    TRADES_FILTER_NOT_REPORTED: 'TRADES_FILTER_NOT_REPORTED',
    TRADES_FILTER_EXECUTION_DATE: 'TRADES_FILTER_EXECUTION_DATE',
    TRADES_FILTER_EXECUTION_TIME: 'TRADES_FILTER_EXECUTION_TIME',

    TRADES_RESET_FILTER: 'TRADES_RESET_FILTER',

    TRADES_REPORTED_FLAG: 'TRADES_REPORTED_FLAG',
    TRADES_REPORTED_REQUEST_STATE: 'TRADES_REPORTED_REQUEST_STATE',
    TRADES_SET_IS_REQUESTING_EXPORT_TRADES: 'TRADES_SET_IS_REQUESTING_EXPORT_TRADES',
    FILTER_DATE_CHANGE: 'TRADES_FILTER_DATE_CHANGE',
    FILTER_CUSTOM_DATE_CHANGE: 'TRADES_FILTER_CUSTOM_DATE_CHANGE',

    TOGGLE_CONFIRMED: 'trades/TOGGLE_CONFIRMED',
    TOGGLE_PENDING: 'trades/TOGGLE_PENDING',
    TOGGLE_REJECTED: 'trades/TOGGLE_REJECTED',

    TOGGLE_UNSETTLED: 'trades/TOGGLE_UNSETTLED',
    TOGGLE_PENDING_SETTLEMENT: 'trades/TOGGLE_PENDING_SETTLEMENT',
    TOGGLE_PENDING_ALLOCATION: 'trades/TOGGLE_PENDING_ALLOCATION',
    TOOGLE_PENDING_PROCEEDS: 'trades/TOOGLE_PENDING_PROCEEDS',
    TOGGLE_SETTLED: 'trades/TOGGLE_SETTLED'

};
