export const gridActions = {
    POSITION_CHANGE: 'grid/POSITION_CHANGE',
    APPLY_EDIT: 'grid/APPLY_EDIT',
    ORDER_BY: 'grid/ORDER_BY',

    DELETE_ROW: 'grid/DELETE_ROW',
    DELETE_SELECTED: 'grid/DELETE_SELECTED',
    DELETE_INVALID: 'grid/DELETE_INVALID',
    DELETE_FLAGGED: 'grid/DELETE_FLAGGED',

    EDITING: 'grid/EDITING',
    SETUP_HEADERS: 'grid/SETUP_HEADERS',
    ADD_COLUMN: 'grid/ADD_COLUMN',
    MOVE_COLUMN_DATA: 'grid/MOVE_COLUMN_DATA',
    REMOVE_COLUMN: 'grid/REMOVE_COLUMN',
    INSERT_DATA_ITEMS: 'grid/IINSERT_DATA_ITEMS',
    REPLACE_DATA_ITEM: 'grid/REPLACE_DATA_ITEM',
    ADD_DATA_ITEMS: 'grid/ADD_DATA_ITEMS',
    APPEND_DATA_ITEMS: 'grid/APPEND_DATA_ITEMS',
    SAME_POSITION: 'grid/SAME_POSITION',
    SET_SELECTION: 'grid/SET_SELECTION',

    VALIDATE: 'grid/VALIDATE_SECURITIES',

    SHOW_HELP: 'grid/SHOW_HELP',
    HIDE_HELP: 'grid/HIDE_HELP',

    DATA_ITEMS_UPLOAD_STATE: 'grid/DATA_ITEMS_UPLOAD_STATE',

    CLEAR: 'grid/CLEAR',
    RESET: 'grid/GRID_RESET',

    BLOCK_INPUT: 'grid/SECURITIES_BLOCK_INPUT',
    DATA_PROCESSING: 'grid/GRID_DATA_PROCESSING',

    GRID_SET_MOUNTED_KEY: 'grid/GRID_SET_MOUNTED_KEY',

    ROWS_LIMIT: 'grid/ROWS_LIMIT',

    CHANGE_ROW_ORDER: 'grid/CHANGE_ROW_ORDER',
    UPDATE_DRAFT: 'grid/UPDATE_DRAFT'
};
