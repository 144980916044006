export const cloManagersChartBar = {
    label: {
        bgColor: '#4a4a4a',
        fontColor: '#fff',
        fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
        bordercolor: '#4a4a4a',
        size: 12,
    },
    tickColor: '#e4e4e4',
    tickFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f',
    },
    barColors: ['#235692', '#669FC3', '#AFE0EB'],
    zeroLineColor: '#e4e4e4',
    margin: { t: 3, r: 0, b: 40, l: 20 },
    barWith: 0.131,
    fontSize: 12,
    yAxisMargin: 1
};

export const trusteeBarColor = '#5B74A5';
