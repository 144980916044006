export const bwicStatuses = {
    scheduled: { title: 'Scheduled', key: 1 },
    bidding: { title: 'Bidding', key: 2 },
    finished: { title: 'Finished', key: 3 },
    cancelled: { title: 'Canceled', key: 4},
    toArray: function () {
        return [this.scheduled, this.bidding, this.finished, this.cancelled];
    },
    getByKey: function (key) {
        return this.toArray().find(s => s.key === key);
    },
    getByTitle: function (title) {
        return this
            .toArray()
            .find(s =>
                s.title.localeCompare(
                    title || '',
                    undefined,
                    { sensitivity: 'accent' }) === 0);
    }
};
