import moment from 'moment';
import { FloaterIndex } from '../../types/amr-pipeline/enums/FloaterIndex';
import { TransactionStatus } from '../../types/amr-pipeline/enums/TransactionStatus';
import { TransactionType } from '../../types/amr-pipeline/enums/TransactionType';
import { Currency } from '../../types/enums/Currency';

export const pipelineRequestCriteria = {
    volumesCount: {
        types: TransactionType.newIssue,
        currencyCode: Currency.USD,
        statuses: [TransactionStatus.Closed, TransactionStatus.Priced],
    },
    shortLongDatedDeals: {
        startDateDashboard: moment.utc([2017, 0, 1]).toDate(),
        startDateCloManagers: moment.utc([2011, 4, 18]).toDate(),
        transactionTypes: [TransactionType.newIssue, TransactionType.refi, TransactionType.reset],
        statuses: [TransactionStatus.Closed, TransactionStatus.Priced],
        nonCallPeriodEndYearsShort: [0, 1.5] as [number, number],
        reinvestmentPeriodEndYearsShort: [0, 3.5] as [number, number],
        nonCallPeriodEndYearsLong: [1.5, 3.5] as [number, number],
        reinvestmentPeriodEndYearsLong: [3.5, 5.5] as [number, number],
    },
    issuanceSpread: {
        startDateDefault: moment.utc([2021, 0, 1]).toDate(),
        startDateCloManagers: moment.utc([2011, 4, 18]).toDate(),
        transactionTypes: [TransactionType.newIssue, TransactionType.refi, TransactionType.reset],
        statuses: [TransactionStatus.Closed, TransactionStatus.Priced],
        floaterIndexes: [FloaterIndex.Fixed, FloaterIndex.Sofr, FloaterIndex.Libor, FloaterIndex.Euribor],
        shortTerm: {
            nonCallPeriodEndYears: [0, 1.5] as [number, number],
            reinvestmentPeriodEndYears: [0, 3.5] as [number, number],
        },
        longTerm: {
            nonCallPeriodEndYears: [1.5, 3.5] as [number, number],
            reinvestmentPeriodEndYears: [3.5, 5.5] as [number, number],
        },
    },
    usBslEsgNewIssue: {
        startDateDefault: moment.utc([2021, 0, 1]).toDate(),
    },
    usBslEuCompliant: {
        startDateDefault: moment.utc([2021, 0, 1]).toDate(),
    },
    kWatchNews: {
        count: 20,
        offset: 0,
    },
    top10Issuers: {
        minYear: 2011,
    },
    top10Arrangers: {
        minYear: 2011
    },
    top10Trustees: {
        minYear: 2013
    },
    debutDeals: {
        minYear: 2021,
        additionalCriteria: {
            isDebut: true,
        }
    },
    staticDeals: {
        additionalCriteria: {
            staticDeal: true,
        },
        minYear: 2021,
    },
};
