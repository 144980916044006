export const allBWICSActions = {
    INIT_COMPLETED: 'ALL_BWICS_INIT_COMPLETED',
    RESET: 'ALL_BWICS_RESET',

    MY_BWICS: 'ALL_BWICS_MY_BWICS',
    ALL_TO_ALL: 'ALL_BWICS_ALL_TO_ALL',

    BWICS_SEARCH_PROGRESS: 'ALL_BWICS_BWICS_SEARCH_PROGRESS',
    BWICS_SEARCH_COMPLETE: 'ALL_BWICS_BWICS_SEARCH_COMPLETE',
    SET_BWIC_COLLAPSED_STATE: 'ALL_BWICS_SET_BWIC_COLLAPSED_STATE',
    SET_COLLAPSE_ALL: 'ALL_BWICS_SET_COLLAPSE_ALL',
    SET_ACTIVE_BWIC: 'ALL_BWICS_SET_ACTIVE_BWIC',

    ALL_BWICS_SET_EXPORTING_STATUS: 'ALL_BWICS_SET_EXPORTING_STATUS',
    ALL_BWICS_NEXT_PAGE_REQUESTING: 'ALL_BWICS_NEXT_PAGE_REQUESTING',
    ALL_BWICS_RESET_PAGING: 'ALL_BWICS_RESET_PAGING',

    LIVE_BIDDING_STAGE2_EXPIRED: 'ALL_BWICS_LIVE_BIDDING_STAGE2_EXPIRED',
    
    ADD_POSITION_BIDS: 'ALL_BWICS_ADD_POSITION_BIDS',
    SORTING_CHANGED: 'ALL_BWICS_SORTING_CHANGED',

    ADVANCED_FILTER_BLOCKED: 'ALL_BWICS_ADVANCED_FILTER_BLOCKED',

    RESET_SUMMARY: "allBwics/RESET_SUMMARY"
};
