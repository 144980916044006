import { AutoCompleteSourceItem } from "../../components/amrPipeline/types/AutoCompleteInputSourceItem";
import { collateralQualityTestValues } from "../collateral-quality-test";

const collateralQualityTestFields = Object.values(collateralQualityTestValues).map(v => ({text: v}));

export const pipelineHistoryFields: AutoCompleteSourceItem[] = [
    { text: 'Transaction Size', synonyms: ['Transaction Balance', 'Total'] },
    { text: 'Target Par' },
    { text: 'Bid Price' },
    { text: 'Ask Price' },
    { text: 'WAS (%)' },
    { text: 'Source - Rated Notes' },
    { text: 'Source - Sub Notes' },
    { text: 'Uses - Purchase Assets' },
    { text: 'Uses - Upfront Fees & Expenses' },
    { text: 'Uses - Interest Reserve' },
    { text: 'Senior Management Fee, bps' },
    { text: 'Sub Management Fee, bps' },
    { text: 'Incentive Management Fee, % ' },
    { text: 'Admin Expense Cap, bps' },
    { text: 'Admin Expense Cap, $' },
    { text: 'Trustee, bps' },
    { text: 'Balance' },
    { text: 'C/E, %', synonyms: ['Credit enhancement'] },
    { text: '% Cap of Structure' },
    { text: 'MVOC, %' },
    { text: 'WAL' },
    { text: 'DM, bps' },
    { text: 'Price' },
    { text: 'O/C Trigger, %' },
    { text: 'O/C Target, %' },
    { text: 'O/C Cushion, %' },
    { text: 'I/C Trigger, %' },
    { text: 'Deal Name' },
    { text: 'Ticker' },
    { text: 'Transaction Type' },
    { text: 'Transaction Status' },
    { text: 'Currency', synonyms: ['CCY'] },
    { text: 'Collateral Type' },
    { text: 'Issuer' },
    { text: 'Co-Issuer' },
    { text: 'Collateral Manager' },
    { text: 'Arranger' },
    { text: 'Trustee' },
    { text: 'Initial Purchaser' },
    { text: 'Transaction Overview' },
    { text: 'Manager Highlights' },
    { text: 'EU Risk Retention Requirements' },
    { text: 'EU Disclosure Requirements' },
    { text: 'ESG Comment' },
    { text: 'Offering Type' },
    { text: 'Intex Deal Name' },
    { text: 'Intex Password' },
    { text: 'Rtg (M/S/F/K/D)', synonyms: ['Rating'] },
    { text: 'Coupon', synonyms: ['Floater Index'] },
    { text: 'Guidance' },
    { text: 'Subscription' },
    { text: 'AMR' },
    { text: 'Class Status', synonyms: ['Tranche Status'] },
    { text: 'EU Compliance' },
    { text: 'ESG' },
    { text: 'Static Deal' },
    { text: 'AMR Deal' },
    { text: 'Roller Dealine' },
    { text: 'Target Pricing' },
    { text: 'Closing Date' },
    { text: 'First Payment Date' },
    { text: 'Non-Call End', synonyms: ['NC End'] },
    { text: 'Reinvestment End', synonyms: ['RI end'] },
    { text: 'Maturity' },
    { text: 'Class', synonyms: ['Tranche'] },
    ...collateralQualityTestFields,
];
