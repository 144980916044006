export const pushDataActions = {
    PUSH_DATA_NEW_BID: 'push/bwic/NEW_BID',
    PUSH_DATA_AXED_FINAL_CHANGE: 'push/bwic/AXED_FINAL_CHANGE',
    PUSH_DATA_NEW_PX_TALK: 'push/bwic/NEW_PX_TALK',
    PUSH_DATA_QUICK_FEEDBACK: 'push/bwic/QUICK_FEEDBACK',
    PUSH_DATA_TARGET_LEVEL: 'push/bwic/TARGET_LEVEL',
    PUSH_DATA_TRADED_AWAY: 'push/bwic/TRADED_AWAY',
    PUSH_DATA_TRADE: 'push/bwic/TRADE',
    PUSH_DATA_COLOR_DISTRIBUTION: 'push/bwic/COLOR_DISTRIBUTION',
    PUSH_DATA_PUBLIC_COLORS: 'push/bwic/PUBLIC_COLORS',
    PUSH_DATA_BWIC_STATUS_CHANGE: 'push/bwic/BWIC_STATUS_CHANGE',
    PUSH_DATA_OPEN_BIDDING_STAGE2_LEVEL: 'push/bwic/LIVE_BIDDING_STAGE2_LEVEL',
    PUSH_DATA_STAGED_BIDDING_START_STAGE2: 'push/bwic/STAGED_BIDDING_START_STAGE2',
    PUSH_DATA_STAGED_BIDDING_FINISH_STAGE2: 'push/bwic/STAGED_BIDDING_FINISH_STAGE2',
    PUSH_DATA_AUTO_FEEDBACK_CONFIG: 'push/bwic/AUTO_FEEDBACK_CONFIG',
    PUSH_DATA_BID_REQUEST: 'push/bwic/BID_REQUEST'
};
