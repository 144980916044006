export const securitiesActions = {
    INITIALIZING: 'SECURITIES_INITIALIZING',

    VALIDATE_TRADE_LIMITS_REQUEST: 'SECURITIES_VALIDATE_TRADE_LIMITS_REQUEST',
    VALIDATE_TRADE_LIMITS_SUCCESS: 'SECURITIES_VALIDATE_TRADE_LIMITS_SUCCESS',
    VALIDATE_TRADE_LIMITS_ERROR: 'SECURITIES_VALIDATE_TRADE_LIMITS_ERROR',
    
    SECURITIES_SET_FLAGGED_MODAL: 'SECURITIES_SET_FLAGGED_MODAL',

    RESET: 'SECURITIES_RESET'
};
