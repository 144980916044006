export const imDetailsDealsActions = {
    DEALS_RESET: 'DEALS_RESET',
    DEALS_REQUESTING_STATE: 'DEALS_REQUESTING_STATE',
    DEAL_DETAILS_REQUESTING_STATE: 'DEAL_DETAILS_REQUESTING_STATE',
    DEALS_TRANCHE_DETAILS_REQUESTUNG_STATE: 'DEALS_TRANCHE_DETAILS_REQUESTUNG_STATE',
    DEALS_STORE_DEALS: 'DEALS_STORE_DEALS',
    DEALS_STORE_AMR_CLASSES: 'DEALS_STORE_AMR_CLASSES',
    DEALS_STORE_DOCUMENTS: 'DEALS_STORE_DOCUMENTS',
    DEALS_STORE_ACTUAL_AMR_CLASSES: 'DEALS_STORE_ACTUAL_AMR_CLASSES',
    DEALS_TOGGLE_DEAL_COLLAPSED_STATE: 'DEALS_TOGGLE_DEAL_COLLAPSED_STATE',
    DEALS_STORE_DOCUMENTS_SEARCH_RESULTS: 'DEALS_STORE_DOCUMENTS_SEARCH_RESULTS',
    DEALS_RESET_DOCUMENTS_SEARCH: "DEALS_RESET_DOCUMENTS_SEARCH"
}
