export const biddingActions = {
    BIDDING_LOADING: 'BIDDING_LOADING',
    BIDDING_STORE_ORIGINAL_BWIC: 'BIDDING_STORE_ORIGINAL_BWIC',
    BIDDING_STORE_VISITOR_TYPE: 'BIDDING_STORE_VISITOR_TYPE',
    BIDDING_IMPROVERS_LOADED: 'BIDDING_IMPROVERS_LOADED',
    BIDDING_BWIC_STATUS_CHANGE: 'BIDDING_BWIC_STATUS_CHANGE', 
    BIDDING_RESET: 'BIDDING_RESET',
    LIVE_BIDDING_STAGE_2_TIMEOUT_END: 'LIVE_BIDDING_STAGE_2_TIMEOUT_END',
    BIDDING_LOAD_PARTICIPANTS_REQUEST_STATUS: 'BIDDING_LOAD_PARTICIPANTS_REQUEST_STATUS',
    STORE_BWIC_PARTICIPANTS: 'BIDDING_STORE_BWIC_PARTICIPANTS'
};
