export const chartDtcSellingBuyingConstants = {
    xAxisRange: [-.5, 6.5],
    margin: { t: 10, r: 0, b: 37, l: 30 },
    yAxisDTick: 800,
    annotationMargin: 27,
    positiveBarColor: '#5b74a5',
    negativeBarColor: '#a52d2a',
    lineColor: '#e4e4e4',
    tickColor: '#e4e4e4',
    tickFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f'
    },
    annotationsFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#4a4a4a'
    },
    dateFormat: 'MMM<br> \'YY',
    hoverlabel: {
        bgcolor: '#4a4a4a',
        bordercolor: '#4a4a4a',
        font: {
            color: '#ffffff',
            family: 'Roboto, Arial, Helvetica, sans-serif',
            size: 12
        }
    },
    yAxisMargin: 15
};
