export const editCompanyActions = {
    EDIT_COMPANY_LOADING_STATUS: 'EDIT_COMPANY_LOADING_STATUS',
    RESET: 'EDIT_COMPANY_RESET',
    COMPANY: 'EDIT_COMPANY_COMPANY',
    COUNTRIES: 'EDIT_COMPANY_COUNTRIES',
    STATES: 'EDIT_COMPANY_STATES',
    CITIES: 'EDIT_COMPANY_CITIES',
    SAVING: 'EDIT_COMPANY_SAVING',
    SAVED: 'EDIT_COMPANY_SAVED',
    SALES_REPRESENTATIVES: 'EDIT_COMPANY_STORE_SALES_REPRESENTATIVES'
};
