export const levelSpecifications = {
    none: { key: 0, title: '' },
    best: { key: 1, title: 'Best' },
    cover: { key: 2, title: 'Cover' },
    toArray: function () {
        return [this.none, this.best, this.cover];
    },
    getByKey: function (key) {
        return this.toArray().find(s => s.key === key);
    }
}
