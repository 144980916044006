export const accountActions = {
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGIN_REFRESH_AUTHENTICATION: 'LOGIN_REFRESH_AUTHENTICATION',

    LOGOUT: 'USERS_LOGOUT',

    LOGIN_LOADING: 'LOGIN_LOADING',

    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
    RESET_PASSWORD_RESET: 'RESET_PASSWORD_RESET',

    SETUP_PASSWORD_SUCCESS: 'SETUP_PASSWORD_SUCCESS',
    SETUP_PASSWORD_FAILURE: 'SETUP_PASSWORD_FAILURE',
    SETUP_PASSWORD_RESET: 'SETUP_PASSWORD_RESET',
    SETUP_PASSWORD_INIT_COMPLETE: 'SETUP_PASSWORD_INIT_COMPLETE',

    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
    CHANGE_PASSWORD_RESET: 'CHANGE_PASSWORD_RESET',

    CHANGE_PHONE_NUMBER_RESET: 'CHANGE_PHONE_NUMBER_RESET',

    CHANGE_BALANCE: 'ACCOUNT_CHANGE_BALANCE'
};
