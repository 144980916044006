export const editParsedBwicActions = {
    INIT_COMPLETED: 'EDIT_PARSE_BWIC_INIT_COMPLETED',
    STORE_ORIGINAL_BWIC: 'EDIT_PARSED_BWIC_STORE_ORIGINAL_BWIC',
    RESET: 'EDIT_PARSED_BWIC_RESET',
    CHANGE_DATE: 'EDIT_PARSED_BWIC_CHANGE_DATE',
    SET_EMAIL_RECEIVED_DATE: 'SET_EMAIL_RECEIVED_DATE',
    BIDS_DUE: 'EDIT_PARSED_BWIC_BIDS_DUE',
    GOOD_UNTIL: 'EDIT_PARSED_BWIC_GOOD_UNTIL',
    DAYS_TO_SETTLE: 'EDIT_PARSED_BWIC_DAYS_TO_SETTLE',
    TIME_ZONE: 'EDIT_PARSED_BWIC_TIME_ZONE',
    RULES_TEXT: 'EDIT_PARSED_BWIC_RULES_TEXT',
    RESERVE_LEVELS_APPLY_CHANHE: 'editParsedBwic/RESERVE_LEVELS_APPLY_CHANHE',
    CAN_TRADE_EARLY_CHANGE: 'editParsedBwic/CAN_TRADE_EARLY_CHANGE',
    CAN_BID_EARLY_CHANGE: 'editParsedBwic/CAN_BID_EARLY_CHANGE',
    PARTIAL_BIDS_ACCEPTED_CHANHE: 'editParsedBwic/PARTIAL_BIDS_ACCEPTED_CHANHE',
    BWIC_PROCESS_TYPE_CHANGE:'editParsedBwic/BWIC_PROCESS_TIME_CHANHE',
    HARD_CUT_OFF_NUMBER_CHANGE:'editParsedBwic/HARD_CUT_OFF_NUMBER_CHANGE',
    STAGE_1_TIME_CHANGE:'editParsedBwic/STAGE_1_TIME_CHANGE',
    STAGE_2_TIME_CHANGE:'editParsedBwic/STAGE_2_TIME_CHANGE',
    BIDS_GOOD_FOR_HOURS_CHANGE:'editParsedBwic/BIDS_GOOD_FOR_HOURS_CHANGE',
    BIDS_GOOD_FOR_MINUTES_CHANGE:'editParsedBwic/BIDS_GOOD_FOR_MINUTES_CHANGE',
    STAGE_2_PARTICIPANTS_CHANGE:'editParsedBwic/STAGE_2_PARTICIPANTS_CHANGE',
    VALIDATE: 'EDIT_PARSED_BWIC_VALIDATE',
    BWIC_SAVED: 'EDIT_PARSED_BWIC_BWIC_SAVED',
    CANCEL_DIALOG: 'EDIT_PARSED_BWIC_CANCEL_DIALOG',
    SAVING_STATE: 'EDIT_PARSED_BWIC_SAVING_STATE'
};
