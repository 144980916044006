import interfaceImage01 from '../images/signPagesCarousel/interface01.png';
import interfaceImage02 from '../images/signPagesCarousel/interface02.png';
import interfaceImage03 from '../images/signPagesCarousel/interface03.png';
import interfaceImage04 from '../images/signPagesCarousel/interface04.png';
import interfaceImage05 from '../images/signPagesCarousel/interface05.png';
import interfaceImage06 from '../images/signPagesCarousel/interface06.png';

export const introCarousel = [
    {
        img: interfaceImage01,
        title: 'Unlock CLO Market Intelligence with K-Watch News',
        text: [
            "Get comprehensive financial insights",
            "View market highlights and graphs",
            "Register for KopenTech webinars",
            "Learn about new platform features"
        ]
    },
    {
        img: interfaceImage02,
        title: 'Get Key Metrics on a Dashboard',
        text: [
            "Visualize BWIC pipeline",
            "Find a perfect time for your next BWIC",
            "Search and export historical data",
            "View your securities scheduled for BWIC"
        ]
    },
    {
        img: interfaceImage03,
        title: 'Analyze BWIC Pipeline',
        text: [
            "Search for relevant securities with filters",
            "See BWIC Px Talk",
            "View BWIC rules",
            "Review historical trade data"
        ]
    },
    {
        img: interfaceImage04,
        title: 'Access CLO Issuance centralized portal',
        text: [
            "Review historical and current new issue, refis, resets",
            "Use advanced filtering and sorting",
            "Export data for further analysis"
        ]
    },
    {
        img: interfaceImage05,
        title: 'Send a BWIC',
        text: [
            "List securities for sale",
            "Set BWIC rules",
            "Invite participants"
        ]
    },
    {
        img: interfaceImage06,
        title: 'Run a BWIC',
        text: [
            "Receive bids electronically",
            "See all bids ranked automatically",
            "Provide instant feedback",
            "Trade in one click"
        ]
    }
];