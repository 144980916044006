export const companyRoles = {
    Administrator: 'Administrator',
    Seller: 'Seller',
    BrokerDealer: 'BrokerDealer',
    Viewer: 'Viewer',
    Media: 'Media',

    getTitle: function (role) {
        switch (role) {
            case this.BrokerDealer: return 'Broker-Dealer';
            default: return role;
        }
    }
};
