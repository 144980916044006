export const profileActions = {
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    CHANGE_PHONE_VISIBLE: 'CHANGE_PHONE_VISIBLE',
    TOGGLE_VISIBILITY_FORM_BLOOMBERG_EMAIL: 'TOGGLE_VISIBILITY_FORM_BLOOMBERG_EMAIL',
    IS_REQUEST_SAVE_BLOOMBERG_EMAIL: 'IS_REQUEST_SAVE_BLOOMBERG_EMAIL',
    SAVE_PHONE_NUMBER: 'SAVE_PHONE_NUMBER',
    CHANGE_PHONE_NUMBER_FAILURE: 'CHANGE_PHONE_NUMBER_FAILURE',
    CHANGE_CONTACT_POPUP_VISIBLE: 'CHANGE_CONTACT_POPUP_VISIBLE',
    CHANGE_PASSWORD_FORM_VISIBLE: 'CHANGE_PASSWORD_FORM_VISIBLE',
    ACCOUNT_SETTINGS_RESET: 'ACCOUNT_SETTINGS_RESET',

    PROFILE_INIT: 'PROFILE_INIT',

    GET_PROFILE_COMPANY_SUCCESS: 'GET_PROFILE_COMPANY_SUCCESS',

    PROFILE_COMPANY_ADD_NEW_TRADER: 'PROFILE_COMPANY_ADD_NEW_TRADER',
    PROFILE_COMPANY_EDIT_TRADER: 'PROFILE_COMPANY_EDIT_TRADER',
    PROFILE_COMPANY_CONTACT_CHANGED: 'PROFILE_COMPANY_CONTACT_CHANGED',

    PROFILE_COMPANY_EDIT_CONTACT: 'PROFILE_COMPANY_EDIT_CONTACT',

    ENABLE_COMPANY_TRADER_REQUEST: 'ENABLE_COMPANY_TRADER_REQUEST',
    ENABLE_COMPANY_TRADER_SUCCESS: 'ENABLE_COMPANY_TRADER_SUCCESS',
    ENABLE_COMPANY_TRADER_FAIL: 'ENABLE_COMPANY_TRADER_FAIL',

    DISABLE_COMPANY_TRADER_REQUEST: 'DISABLE_COMPANY_TRADER_REQUEST',
    DISABLE_COMPANY_TRADER_SUCCESS: 'DISABLE_COMPANY_TRADER_SUCCESS',
    DISABLE_COMPANY_TRADER_FAIL: 'DISABLE_COMPANY_TRADER_FAIL',

    PROFILE_SMS_DEACTIVATING: 'PROFILE_SMS_DEACTIVATING',
    PROFILE_SMS_DEACTIVATED: 'PROFILE_SMS_DEACTIVATED',
    PROFILE_SMS_ACTIVATION_CODE_REQUESTED: 'PROFILE_SMS_ACTIVATION_CODE_REQUESTED',
    PROFILE_IDENTITY_PHONE_SAVING_STATE: 'PROFILE_IDENTITY_PHONE_SAVING_STATE',
    PROFILE_IDENTITY_PHONE_SAVED: 'PROFILE_IDENTITY_PHONE_SAVED',
    PROFILE_IDENTITY_PHONE_CONFIRMED: 'PROFILE_IDENTITY_PHONE_CONFIRMED',
    PROFILE_VERIFICATION_CODE_SENDING_STATE: 'PROFILE_VERIFICATION_CODE_SENDING_STATE',
    PROFILE_PHONE_VERIFICATION_CODE_VALID_STATE: 'PROFILE_PHONE_VERIFICATION_CODE_VALID_STATE',
    PROFILE_IDENTITY_PHONE_EDIT: 'PROFILE_IDENTITY_PHONE_EDIT',
    PROFILE_SET_VISIBLE_UPLOAD_USER_IMAGE_POPUP: 'PROFILE_SET_VISIBLE_UPLOAD_USER_IMAGE_POPUP',
    PROFILE_SET_REQUEST_STATE_UPLOAD_USER_IMAGE: 'PROFILE_SET_REQUEST_STATE_UPLOAD_USER_IMAGE',
    PROFILE_SET_REQUEST_STATE_DELETE_USER_IMAGE: 'PROFILE_SET_REQUEST_STATE_DELETE_USER_IMAGE',
};
