export const amrPipelineActions = {
    PIPELINE_INITIAL_LOAD: "AMR_PIPELINE_INITIAL_LOAD",
    PIPELINE_SUMMARY_REQUESTING_STATE: "AMR_PIPELINE_SUMMARY_REQUESTING_STATE",
    PIPELINE_STORE_SUMMARY: "AMR_PIPELINE_STORE_SUMMARY",
    PIPELINE_STORE_TRANSACTIONS: "AMR_PIPELINE_STORE_TRANSACTIONS",
    PIPELINE_STORE_CLASSES: "AMR_PIPELINE_STORE_CLASSES",
    PIPELINE_SEARCH_TRANSACTIONS: "AMR_PIPELINE_SEARCH_TRANSACTIONS",
    PIPELINE_SEARCH_CLASSES: "AMR_PIPELINE_SEARCH_CLASSES",
    PIPELINE_INITIAL_FILTER: "AMR_PIPELINE_INITIAL_FILTER",
    PIPELINE_FILTER_STATE: "AMR_PIPELINE_FILTER_STATE",
    PIPELINE_FILTER_STORE_DATA: "AMR_PIPELINE_FILTER_STORE_DATA",
    PIPELINE_FILTER_RESET: "AMR_PIPELINE_FILTER_RESET",
    PIPELINE_FILTER_RESET_VISIBILITY: "AMR_PIPELINE_FILTER_RESET_VISIBILITY",
    PIPELINE_FILTER_MANAGERS_LOADED: "AMR_PIPELINE_FILTER_MANAGERS_LOADED",
    PIPELINE_FILTER_ARRANGERS_LOADED: "AMR_PIPELINE_FILTER_ARRANGERS_LOADED",
    PIPELINE_FILTER_CLASS_NAMES_LOADED: "AMR_PIPELINE_FILTER_CLASS_NAMES_LOADED",
    PIPELINE_FILTER_TRUSTEE_LOADED: "AMR_PIPELINE_FILTER_TRUSTEE_LOADED",
    PIPELINE_FILTER_SELECT_FROM_CONFIG: "AMR_PIPELINE_FILTER_SELECT_FROM_CONFIG",
    PIPELINE_FILTER_SET_BY_REFERENCE: "AMR_PIPELINE_FILTER_SET_BY_REFERENCE",
    PIPELINE_FILTER_SELECT_CHANGE: "PIPELINE_FILTER_SELECT_CHANGE",
    PIPELINE_FILTER_RANGE_CHANGE: "PIPELINE_FILTER_RANGE_CHANGE",
    PIPELINE_FILTER_RANGE_CLEAR_ALL: "PIPELINE_FILTER_RANGE_CLEAR_ALL",
    PIPELINE_FILTER_SELECT_CLEAR_ALL: "PIPELINE_FILTER_SELECT_CLEAR_ALL",
    PIPELINE_FILTER_SELECT_SELECT_ALL: "PIPELINE_FILTER_SELECT_SELECT_ALL",
    PIPELINE_FILTER_RADIO_CHANGE: "PIPELINE_FILTER_RADIO_CHANGE",
    PIPELINE_FILTER_RADIO_CLEAR: "PIPELINE_FILTER_RADIO_CLEAR",
    PIPELINE_FILTER_DATE_CLEAR: "PIPELINE_FILTER_DATE_CLEAR",
    PIPELINE_FILTER_DATE_SELECT_OPTION: "PIPELINE_FILTER_DATE_SELECT_OPTION",
    PIPELINE_FILTER_DATE_TIME_SELECT_OPTION: "PIPELINE_FILTER_DATE_TIME_SELECT_OPTION",
    PIPELINE_FILTER_DATE_SELECT_CUSTOM_RANGE: "PIPELINE_FILTER_DATE_SELECT_CUSTOM_RANGE",
    PIPELINE_FILTER_DATE_TIME_SELECT_CUSTOM_RANGE: "PIPELINE_FILTER_DATE_TIME_SELECT_CUSTOM_RANGE",
    PIPELINE_FILTER_DATE_SELECT_YEARS_RANGE: "PIPELINE_FILTER_DATE_SELECT_YEARS_RANGE",
    PIPELINE_FILTER_VISIBILITY_CHANGE: "PIPELINE_FILTER_VISIBILITY_CHANGE",
    PIPELINE_FILTER_MAKE_VISIBLE: "PIPELINE_FILTER_MAKE_VISIBLE",
    PIPELINE_SEARCH_COMPLETE: "AMR_PIPELINE_SEARCH_COMPLETE",
    PIPELINE_SHOW_MANAGER_POPUP: "AMR_PIPELINE_SHOW_MANAGER_POPUP",
    PIPELINE_DEAL_SORTING_CHANGED: "AMR_PIPELINE_DEAL_SORTING_CHANGED",
    PIPELINE_CLASS_SORTING_CHANGED: "AMR_PIPELINE_CLASS_SORTING_CHANGED",
    PIPELINE_RESET_TRANSACTIONS: 'AMR_PIPELINE_RESET_TRANSACTIONS',
    PIPELINE_SET_EXPANDED: "AMR_PIPELINE_SET_EXPANDED",
    PIPELINE_APPLY_FILTER: "AMR_PIPELINE_APPLY_FILTER",
    PIPELINE_RESET: "AMR_PIPELINE_RESET",
    PIPELINE_RESET_FILTER_STATE: "AMR_PIPELINE_RESET_FILTER_STATE",
    PIPELINE_STORE_USER_COMPANY: "PIPELINE_STORE_USER_COMPANY",
    PIPELINE_SET_REDIRECT_FILTER: "PIPELINE_SET_REDIRECT_FILTER",
    PIPELINE_EXPORT_LOAD: "PIPELINE_EXPORT_LOAD",
    PIPELINE_SET_SYNDICATE_CONTACTS: "PIPELINE_SET_SYNDICATE_CONTACTS",
    PIPELINE_SET_ANY_IOIS_EXISTS: "PIPELINE_SET_ANY_IOIS_EXISTS",
};
