export const uploadDocumentsActions = {
    DEALS: 'DOCUMENTS_DEALS',
    UPLOAD_ALL_DIALOG: 'DOCUMENTS_UPLOAD_ALL_DIALOG',
    UPLOAD_DIALOG: 'DOCUMENTS_UPLOAD_DIALOG',
    RESET: 'DOCUMENTS_RESET',
    REFRESH_TIME: 'REFRESH_TIME',
    BEGIN_DOCUMENT_UPLOAD: 'BEGIN_DOCUMENT_UPLOAD',
    PROGRESS_DOCUMENT_UPLOAD: 'PROGRESS_DOCUMENT_UPLOAD',
    COMPLETE_DOCUMENT_UPLOAD: 'COMPLETE_DOCUMENT_UPLOAD',
    SET_REQUEST_STATUS_DELETING_DOCUMENT: 'SET_REQUEST_STATUS_DELETING_DOCUMENT',
};
