export const chartBarDnt = {
    chartLayout: {
        margin: { t: 7, r: 0, b: 35, l: 30 },
        font: { size: 12 },
        bargap: 0.15,
        bargroupgap: 0.1,
    },
    defaultYRangeMaxValue: 10,
    defaultDTick: 2,
    tickColor: '#e4e4e4',
    tickFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f'
    },
    xAxisRange: [-0.5, 7.5],
    barColors: ['#74b58f', '#75afbd', '#5c4f84'],
    zeroLineColor: '#e4e4e4',
    dTick: 10,
    yAxisLastLineMargin: 3,
    hoverlabel: {
        bgcolor: '#4a4a4a',
        bordercolor: '#4a4a4a',
        font: {
            color: '#ffffff',
            family: 'Roboto, Arial, Helvetica, sans-serif',
            size: 12
        },
        align: 'left'
    }
};
