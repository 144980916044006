import { PipelineColumn } from '../../components/amrPipeline/types/PipelineColumn';
import { roles } from "../";

interface IncludeExcludeRoles {
    includeFor?: string[];
    excludeFor?: string[];
}

interface UserRoleConfines {
    [key: string]: IncludeExcludeRoles;
}

export const pipelineRoleColumns: UserRoleConfines = {
    [PipelineColumn.intex]: {
        includeFor: [...roles.admin(), ...roles.seller(), roles.DataEntry],
    },
    [PipelineColumn.iois]: {
        excludeFor: [...roles.admin(), roles.DataEntry],
    },
};
