import { Equity } from '../../types/amr-pipeline/enums/Equity';

export const equity = [Equity.Yes, Equity.YesMinority, Equity.YesMajorityMinority, Equity.No];

export const equityTitles = {
    [Equity.No]: 'No',
    [Equity.Yes]: 'Yes',
    [Equity.YesMinority]: 'Yes (Minority)',
    [Equity.YesMajorityMinority]: 'Yes (Majority&Minority)',
};
