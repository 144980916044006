export const profileTabs = {
    parent: {
        account: 'account',
        company: 'company'
    },
    personalInfo: 'Personal Info',
    passwordSettings: 'Password Settings',
    twoFactorAuthenticationSettings: 'Two-factor Authentication',
    notifications: 'Notifications',
    company: 'Company',
    traders: 'Traders',
    tradingLimits: 'TradingLimits',
    subscriptions: 'Subscriptions',
    manageDealers: 'ManageDealers',
    managePlatformUsers: 'ManagePlatformUsers'
};
