import { CollateralQualityTestValue } from "../types/amr-pipeline/enums/CollateralQualityTestValue";

export const collateralQualityTestValues = {
    [CollateralQualityTestValue.AssetPar]: "Asset Par",
    [CollateralQualityTestValue.BondsPercentage]: "Bonds, %",
    [CollateralQualityTestValue.FitchWeightedAverageRatingFactor]: "Fitch WARF",
    [CollateralQualityTestValue.FitchWeightedAverageRecoveryRatePercentage]: "Fitch Weighted Average Recovery Rate, %",
    [CollateralQualityTestValue.MoodysCorrelationTest]: "Moody's Correlation Test",
    [CollateralQualityTestValue.MoodysDiversityScore]: "Moody's Diversity Score",
    [CollateralQualityTestValue.MoodysWARF]: "Moody's WARF",
    [CollateralQualityTestValue.MoodysWeightedAverageRecoveryRatePercentage]: "Moody's Weighted Average Recovery Rate, %",
    [CollateralQualityTestValue.SnPWeightedAverageRecoveryRatePercentage]: "S&P Weighted Average Recovery Rate, %",
    [CollateralQualityTestValue.WalYears]: "WAL, years",
    [CollateralQualityTestValue.WasPercentage]: "WAS, %",
    [CollateralQualityTestValue.WeightedAverageCoupon]: "Weighted Average Coupon",
    [CollateralQualityTestValue.WeightedAverageCouponOfFixedRateAssets]: "Weighted Average Coupon of Fixed Rate Assets",
    [CollateralQualityTestValue.WeightedAverageCouponOfFloatingRateAssets]: "Weighted Average Coupon of Floating Rate Assets",
    [CollateralQualityTestValue.PurchasePrice]: "Purchase Price, %",
    [CollateralQualityTestValue.LiborFloor]: "Libor Floor, %",
    [CollateralQualityTestValue.FirstLienLoans]: "First Lien Loans, %",
    [CollateralQualityTestValue.SecondLienLoans]: "Second Lien Loans, %",
    [CollateralQualityTestValue.CovLite]: "Cov-Lite, %",
    [CollateralQualityTestValue.MoodysCaaAssets]: "Moody's Caa assets, %",
    [CollateralQualityTestValue.SPCCCAssets]: "S&P CCC assets, %",
}

