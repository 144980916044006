export const manageParsedBwicsActions = {
    RESET: 'MANAGE_PARSED_BWICS_RESET',

    LOADING: 'MANAGE_PARSED_BWICS_LOADING',

    INITIAL_FILTER: 'MANAGE_PARSED_BWICS_INITIAL_FILTER',
    RESET_FILTERS: 'MANAGE_PARSED_BWICS_RESET_FILTERS',
    FILTER_STATE: 'MANAGE_PARSED_BWICS_FILTER_STATE',

    REFRESH_LAST_UPDATED_DATE: 'MANAGE_PARSED_BWICS_REFRESH_LAST_UPDATED_DATE',

    FILTER_STATUSES_LOADED: 'MANAGE_PARSED_BWICS_FILTER_STATUSES_LOADED',
    FILTER_STATE_LOADED: 'MANAGE_PARSED_BWICS_FILTER_STATE_LOADED',

    SEARCH_PROGRESS: 'MANAGE_PARSED_BWICS_SEARCH_PROGRESS',
    SEARCH_COMPLETE: 'MANAGE_PARSED_BWICS_SEARCH_COMPLETE',
    UPDATE_BWIC_LIST: 'MANAGE_PARSED_BWICS_UPDATE_LIST',

    FILTER_PUBLISHED_STATE_SELECTION_CHANGE: 'MANAGE_PARSED_BWICS_FILTER_STATE_SELECTION_CHANGE',
    FILTER_STATUS_SELECTION_CHANGE: 'MANAGE_PARSED_BWICS_FILTER_STATUS_SELECTION_CHANGE',
    FILTER_DATE_CHANGE: 'MANAGE_PARSED_BWICS_FILTER_DATE_CHANGE',
    FILTER_CUSTOM_DATE_CHANGE: 'MANAGE_PARSED_BWICS_FILTER_CUSTOM_DATE_CHANGE',

    SET_BWIC_COLLAPSED_STATE: 'MANAGE_PARSED_BWICS_SET_BWIC_COLLAPSED_STATE',
    SET_COLLAPSE_ALL: 'MANAGE_PARSED_BWICS_SET_COLLAPSE_ALL',
    POSITION_SELECTION_CHANGE: 'MANAGE_PARSED_BWICS_POSITION_SELECTION_CHANGE',

    PUBLISH_DIALOG: 'MANAGE_PARSED_BWICS_PUBLISH_DIALOG',
    UNPUBLISH_DIALOG: 'MANAGE_PARSED_BWICS_UNPUBLISH_DIALOG',
    DELETE_BWIC_DIALOG: 'MANAGE_PARSED_BWICS_DELETE_BWIC_DIALOG',

    EDIT_PXTALK: 'MANAGE_PARSED_BWICS_EDIT_PXTALK',
    UPDATE_PXTALK: 'UPDATE_PXTALK',

    EDIT_COLOR: 'MANAGE_PARSED_BWICS_EDIT_COLOR',
    UPDATE_COLOR: 'MANAGE_PARSED_BWICS_UPDATE_COLOR',

    SET_FILTER_APPLIED_STATE: 'MANAGE_PARSED_BWICS_SET_FILTER_APPLIED_STATE',

    MANAGE_PARSED_BWICS_NEXT_PAGE_REQUESTING: 'MANAGE_PARSED_BWICS_NEXT_PAGE_REQUESTING',
    MANAGE_PARSED_BWICS_RESET_PAGING: 'MANAGE_PARSED_BWICS_RESET_PAGING',
    MANAGE_PARSED_BWICS_SET_REQUEST_STATUS_IS_AMR: 'MANAGE_PARSED_BWICS_SET_REQUEST_STATUS_IS_AMR',
    MANAGE_PARSED_BWICS_SET_IS_AMR_FLAG: 'MANAGE_PARSED_BWICS_SET_IS_AMR_FLAG'
};
