import { CollateralType } from "../../types/amr-pipeline/enums/CollateralType";
import { constants } from "../constants";

export const CollateralTypesList = [
    {
        value: constants.allListItem,
        text: 'All',
    },
    {
        text: 'BSL',
        value: CollateralType.broadlySyndicated,
    },
    {
        text: 'MM',
        value: CollateralType.middleMarket,
    },
];
