export const colorDistributionTypes = {
    status: { key: 1, title: 'Status' },
    coverHandle: { key: 2, title: 'Cover - Handle' },
    coverExact: { key: 3, title: 'Cover - Exact' },
    bestHandle: { key: 4, title: 'Best - Handle' },
    bestExact: { key: 5, title: 'Best - Exact' },
    traded: function () {
        return [this.coverHandle, this.coverExact];
    },
    dnt: function () {
        return [this.bestHandle, this.bestExact];
    }

};
