export const companiesActions = {
    INIT_COMPLETED: 'COMPANIES_INIT_COMPLETED',
    RESET: 'COMPANIES_RESET',
    RESET_EDIT_MEMBER: 'RESET_EDIT_MEMBER',
    COMPANIES: 'COMAINES_LOADED',
    USERS: 'COMPANIES_USERS_LOADED',
    USER_REQUESTS: 'companies/USER_REQUESTS',
    ORDER_BY: 'COMPANIES_ORDER_BY',
    COMPANY_STATUS_CHANGE_CONFIRMATION: 'COMPANY_STATUS_CHANGE_CONFIRMATION',
    RESEND_INVITE_CONFIRMATION: 'COMPANIES_RESEND_INVITE_CONFIRMATION',
    RESET_PASSWORD_CONFIRMATION: 'COMPANIES_RESET_PASSWORD_CONFIRMATION',
    MEMBER_STATUS_CHANGE_CONFIRMATION: 'MEMBER_STATUS_CHANGE_CONFIRMATION',
    TFA_STATUS_CHANGE_CONFIRMATION: 'TFA_STATUS_CHANGE_CONFIRMATION',

    TFA_ENABLED_STATE_CHANGED: 'TFA_ENABLED_STATE_CHANGED',
    COMPANY_FILTER_CHNAGED: 'COMPANY_FILTER_CHNAGED',
    MEMBERS_FILTER_CHNAGED: 'MEMBERS_FILTER_CHNAGED',
    COUNTRIES: 'COMPANIES_COUNTRIES',

    MEMBERS_ORDER_BY: 'COMPANIES_MEMNERS_ORDER_BY',
    COMPANY_UPDATE_INIT_STATUS_EDIT_USER: 'COMPANY_UPDATE_INIT_STATUS_EDIT_USER',
    COMPANY_SET_EDITING_USER: 'COMPANY_SET_EDITING_USER',
    SEND_INVITE_CHANGE: 'COMPANIES_SEND_INVITE_CHANGE',
    SAVING_USER: 'COMPANIES_SAVING_USER',
    COMPANY_APPROVAL_CONFIRMATION_SHOW: 'COMPANY_APPROVAL_CONFIRMATION_SHOW',
    COMPANY_APPROVAL_CONFIRMATION_HIDE: 'COMPANY_APPROVAL_CONFIRMATION_HIDE',
    COMPANY_REJECTION_CONFIRMATION_SHOW: 'COMPANY_REJECTION_CONFIRMATION_SHOW',
    COMPANY_REJECTION_CONFIRMATION_HIDE: 'COMPANY_REJECTION_CONFIRMATION_HIDE',
    COMPANY_APPROVE_REQUEST: 'COMPANY_APPROVE_REQUEST',
    COMPANY_APPROVE_SUCCESS: 'COMPANY_APPROVE_SUCCESS',
    COMPANY_APPROVE_FAILURE: 'COMPANY_APPROVE_FAILURE',
    COMPANY_REJECT_REASON_SET: 'COMPANY_REJECT_REASON_SET',
    COMPANY_MEMBER_DELETED: 'COMPANY_MEMBER_DELETED',
    COMPANY_MEMBER_SET_OFAC_REQUEST: 'COMPANY_MEMBER_SET_OFAC_REQUEST',
    COMPANY_MEMBER_SET_OFAC_SUCCESS: 'COMPANY_MEMBER_SET_OFAC_SUCCESS',
    COMPANY_MEMBER_SET_OFAC_FAILURE: 'COMPANY_MEMBER_SET_OFAC_FAILURE',
    COMPANY_SET_OFAC_REQUEST: 'COMPANY_SET_OFAC_REQUEST',
    COMPANY_SET_OFAC_SUCCESS: 'COMPANY_SET_OFAC_SUCCESS',
    COMPANY_SET_OFAC_FAILURE: 'COMPANY_SET_OFAC_FAILURE',
    COMPANY_GET_USER_LIST_REQUEST_STATUS: 'COMPANY_GET_USER_LIST_REQUEST_STATUS',
    MANAGE_EXPORT_USERS_REQUEST: 'MANAGE_EXPORT_USERS_REQUEST',
    MANAGE_EXPORT_USERS_SUCCESS: 'MANAGE_EXPORT_USERS_SUCCESS',
    MANAGE_EXPORT_USERS_FAILURE: 'MANAGE_EXPORT_USERS_FAILURE',
    MANAGE_SET_REQUEST_STATE_EXPORT_COMPANIES: 'MANAGE_SET_REQUEST_STATE_EXPORT_COMPANIES',
    COMPANY_MEMBER_SET_USER_LAST_LOGIN_HISTORY: 'COMPANY_MEMBER_SET_USER_LAST_LOGIN_HISTORY',
    COMPANY_MEMBER_RESET_USER_LAST_LOGIN_HISTORY: 'COMPANY_MEMBER_RESET_USER_LAST_LOGIN_HISTORY',

    COMPANY_MEMBER_SUBSCRIPTION_CHANGE: 'COMPANY_MEMBER_SUBSCRIPTION_CHANGE',
    COMPANY_SUBSCRIPTION_CHANGE: 'COMPANY_SUBSCRIPTION_CHANGE',

    STORE_SALES_REPRESENTATIVES: 'COMPANIES_STORE_SALES_REPRESENTATIVES',

    COMPANY_SET_CLEARING_BANK: 'COMPANY_SET_CLEARING_BANK',

    COMPANY_PUSH_DATA_RECEIVED: 'COMPANY_PUSH_DATA_RECEIVED',
    COMPANY_FILTER_APPLY: 'manage-companies/COMPANY_FILTER_APPLY',
    MEMBER_FILTER_APPLY: 'manage-members/MEMBER_FILTER_APPLY',
    COMPANY_FILTER_RESET: 'manage-companies/COMPANY_FILTER_RESET',
    MEMBER_FILTER_RESET: 'manage-members/MEMBER_FILTER_RESET',
    RESET_SUBSCRIPTION: 'RESET_SUBSCRIPTION',
};
