export const kIndicator = {
    dateFormat: 'MMM<br/> \'YY',
    margin: { t: 12, r: 10, b: 37, l: 20 },
    yaxisDtick: 1,
    layoutFont: {
        size: 12
    },
    hoverlabel: {
        bgcolor: '#4a4a4a',
        bordercolor: '#4a4a4a',
        font: {
            color: '#ffffff',
            family: 'Roboto, Arial, Helvetica, sans-serif',
            size: 12
        },
        align: 'left'
    },
    tickColor: '#e4e4e4',
    xAxisTickFormat: '%b <br> \'%y',
    xAxisDTick: 'M1',
    traceMarker: {
        color: '#235692',
        width: 6
    },
    tickFont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 12,
        color: '#7f7f7f'
    },
    zerolinecolor: '#F0592A',
    titlefont: {
        family: 'Roboto, Arial, Helvetica, sans-serif',
        size: 16,
        color: '#7f7f7f'
    }
};
